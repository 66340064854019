import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Apply from "@/components/Apply";
import Footer from "@/components/Footer";
import { Carousel } from "antd";
import ApplyModal from "@/components/ApplyModal";
import UnlimitedCarousel from "@/components/UnlimitedCarousel";
const topTabList = [
  {
    key: 1,
    label: "一仓三多",
    imgUrl: "oneWare_threeMore_bg",
    icon: "oneWare_threeMore_icon",
  },
  {
    key: 2,
    label: "商品管理",
    imgUrl: "goods_manage_bg",
    icon: "goods_manage_icon",
  },
  {
    key: 3,
    label: "库存管理",
    imgUrl: "stock_manage_bg",
    icon: "stock_manage_icon",
  },
  {
    key: 4,
    label: "促销管理",
    imgUrl: "sales_manage_bg1",
    icon: "sales_manage_icon",
  },
  {
    key: 5,
    label: "订单履约",
    imgUrl: "order_manage_bg",
    icon: "order_manage_icon",
  },
  {
    key: 6,
    label: "聚合配送",
    imgUrl: "dispatching_manage",
    icon: "dispatching_manage_icon",
  },
  {
    key: 7,
    label: "采购管理",
    imgUrl: "purchase_manage",
    icon: "purchase_manage_icon",
  },
];
const bottomTabList = [
  {
    key: 8,
    label: "财务",
    imgUrl: "finance_manage",
    icon: "finance_manage_icon",
  },
  {
    key: 9,
    label: "数据",
    imgUrl: "data_manage_bg",
    icon: "data_manage_icon",
  },
  {
    key: 10,
    label: "巡店管理",
    imgUrl: "shop_manage",
    icon: "shop_manage_icon",
  },
  {
    key: 11,
    label: "门店收银POS",
    imgUrl: "pos_manage",
    icon: "pos_manage_icon",
  },
  {
    key: 12,
    label: "中心仓",
    imgUrl: "center_warehouse",
    icon: "center_warehouse_icon",
  },
  {
    key: 13,
    label: "加盟",
    imgUrl: "join_in",
    icon: "join_in_icon",
  },
  {
    key: 14,
    label: "供应商协同",
    imgUrl: "supplier",
    icon: "supplier_icon",
  },
];
const rxLeftList = [
  {
    icon: "rx_icon_1",
    title: "多渠道管理",
    text: "商品共享、聚合订单、聚合营销",
  },
  {
    icon: "rx_icon_2",
    title: "多业态管理",
    text: "一仓多店、库存共享",
  },
  {
    icon: "rx_icon_3",
    title: "商品管理",
    text: "标品库管理、异常商品管理、商品组合销售",
  },
  {
    icon: "rx_icon_4",
    title: "履约管理",
    text: "合单拣货、拣货路线、聚合配送",
  },
  {
    icon: "rx_icon_5",
    title: "仓店管理",
    text: "入、出、盘、拣一体化、效期管理",
  },
];
const rxRightList = [
  {
    icon: "rx_icon_6",
    title: "财务管理",
    text: "盈亏分析、支出风险识别、多核销算法",
  },
  {
    icon: "rx_icon_7",
    title: "供应链管理",
    text: "支持自动采购建议、多采购模式、供应商工具",
  },
  {
    icon: "rx_icon_8",
    title: "数据中台管理",
    text: "配送分析、履约分析、商品分析、营业分析、活动分析",
  },
  {
    icon: "rx_icon_9",
    title: "客服中心",
    text: "聚合多渠道客服沟通功能、评价处理",
  },
  {
    icon: "rx_icon_10",
    title: "员工管理",
    text: "工作待办、效率追踪、绩效管理",
  },
];
const carouselList = [
  "phone_homepage_new",
  "phone_order",
  "phone_work_platform",
  "phone_warehouse_manage",
  "phone_mine",
];
let tabIndex = 1;
const FrontWarehouse = () => {
  const [tabActive, setTabActive] = useState(1);
  const [tabsIcon, setTabIcon] = useState("oneWare_threeMore_icon");
  const [tabImgUrl, setTabImgUrl] = useState("oneWare_threeMore_bg");
  const [isLeftImageLarge, setIsLeftImageLarge] = useState(true); // 定义状态来控制图片是否放大
  const [showModal, setShowModal] = useState(false);
  const [isClicked, setIsClicked] = useState(false); // 是否点击div
  const inactiveTabs = [8, 10, 12];
  const isActive = tabActive > 7 && !inactiveTabs.includes(tabActive);

  const intervalRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    // 点击div之后不再设置定时器
    if (!isClicked) {
      startInterval();
    }
    // 清除定时器
    return () => clearInterval(intervalRef.current);
  }, [isClicked]);

  useEffect(() => {
    window.scrollTo(0, 0); // 跳转后滚动到顶部
  }, []);

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      tabIndex += 1;
      if (tabIndex > 14) {
        tabIndex = 1;
      }
      setTabActive(tabIndex);
      const isBottomTab = tabIndex > 7;
      const tabList = isBottomTab ? bottomTabList : topTabList;
      const tabData = isBottomTab
        ? tabList[tabIndex - 8]
        : tabList[tabIndex - 1];

      setTabIcon(tabData.icon);
      if (
        !isBottomTab ||
        (tabIndex !== 8 && tabIndex !== 10 && tabIndex !== 12)
      ) {
        setTabImgUrl(tabData.imgUrl);
      }
    }, 2000);
  };
  const stopInterval = () => {
    clearInterval(intervalRef.current);
  };

  const modalShow = () => {
    if (!showModal) {
      setShowModal(true);
    }
  };
  const onCancel = () => {
    setShowModal(false);
  };

  const handleChange = (key, icon, tabImgUrl) => {
    if (key != 8 && key != 10 && key != 12) {
      setTabActive(key);
      setTabIcon(icon);
      setTabImgUrl(tabImgUrl);
    } else {
      setTabActive(key);
      setTabIcon(icon);
    }
  };
  return (
    <div className="front_warehouse_wrapper">
      {/* <Connect /> */}
      <ApplyModal isShow={showModal} onCancel={onCancel} />
      <div className="carousel_wrapper_front">
        <img
          style={{ width: "100%" }}
          // src={require(`@/images/homePage/front_warehouse.webp`)}
          src={`https://static.hiboos.com/static/yzt-home/homePage/front-warehouse.webp`}
          alt=""
        />
        {/* <div className='text_wrapper'>
          <p className='text'>前置仓/闪电仓</p>
          <p className='text'>全渠道全链路数字化解决方案</p>
        </div> */}
        <div className="opt_btn_wrapper">
          <div className="opt_btn" onClick={modalShow}>
            免费咨询
          </div>
          <div
            className="about_nowarehouse"
            onClick={() => {
              navigate("/no-warehouse");
            }}
          >
            <span>了解无人仓</span>
            <img
              width={14}
              height={8}
              src={require(`@/images/frontWarehouse/about_arrow.png`)}
              alt=""
            />
          </div>
        </div>

        <div className="banner_carousel_container">
          <div className="banner_carousel_text">
            <p>为不同业态和规模的商家提供更好用的解决方案</p>
            <img
              src={require(`@/images/downArrow.png`)}
              width={16}
              height={17}
              style={{ marginLeft: "5px" }}
              alt=""
            />
          </div>
          <div className="small_carousel_wrapper">
            <UnlimitedCarousel
              idname="frontWarehouse"
              imgsrc={require(`@/images/frontWarehouse/front_warehouse_long.png`)}
              wrapStyles={{ width: "1172px", height: "133px" }}
              styles={{ width: "1172px", height: "92px" }}
            />
          </div>
        </div>
      </div>

      <div className="work_scene">
        <div className="front_warehouse_pain_spot">
          <p className="title_big">前置仓运营痛点</p>
          <div className="wrapper pain_spot_icon">
            <img
              height={450}
              src={
                "https://static.hiboos.com/static/yzt-home/frontWarehouse/manage_pain_point.png"
              }
              alt=""
            />
          </div>
        </div>
        {/* 解决方案 */}
        <div className="front_warehouse_rx">
          <div className="wrapper">
            <p className="title_big">海博前置仓系统解决方案</p>
            <div className="main_content">
              <div className="content_left">
                {rxLeftList.map((item, index) => {
                  return (
                    <div className="item" key={index}>
                      <div className="item_left">
                        <p className="top">{item.title}</p>
                        <p className="bottom">{item.text}</p>
                      </div>
                      <div className="item_right">
                        {/* <img height={48} src={`https://static.hiboos.com/static/yzt-home/frontWarehouse/${item.icon}.png`} alt="" /> */}
                        <img
                          height={48}
                          src={`https://static.hiboos.com/static/yzt-home/frontWarehouse/${item.icon}.png`}
                          alt=""
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="content_center">
                <div className="phone_black_border"></div>
                <div className="carousel_wrapper">
                  <Carousel autoplay dotPosition="none">
                    {carouselList.map((key) => {
                      return (
                        <img
                          key={key}
                          style={{ marginTop: "16px", borderRadius: "30px" }}
                          // width={273}
                          // height={560}
                          src={`https://static.hiboos.com/static/yzt-home/frontWarehouse/${key}.png`}
                          alt=""
                        />
                      );
                    })}
                  </Carousel>
                </div>
              </div>
              <div className="content_right">
                {rxRightList.map((item, index) => {
                  return (
                    <div className="item" key={index}>
                      <div className="item_left">
                        <img
                          height={48}
                          src={`https://static.hiboos.com/static/yzt-home/frontWarehouse/${item.icon}.png`}
                          alt=""
                        />
                      </div>
                      <div className="item_right">
                        <p className="top">{item.title}</p>
                        <p className="bottom">{item.text}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/* business */}
        <div className="front_warehouse_business">
          <div className="wrapper">
            <p className="title_big">针对加盟/连锁大规模客户</p>
            <div className="card_wrapper">
              <div className="card_item card_item_left">
                <div className="icon"></div>
                <p className="title">加盟</p>
                <p className="text">
                  提供加盟业务管理系统与加盟商系统，助力商户快速扩展业务。
                </p>
              </div>
              <div className="card_item card_item_center">
                <div className="icon"></div>
                <p className="title">巡店</p>
                <p className="text">
                  从标准制定-巡店计划制定-巡店任务分发-门店整改-数据分析全流程闭环巡店流程。
                </p>
              </div>
              <div className="card_item card_item_right">
                <div className="icon"></div>
                <p className="title">中心仓</p>
                <p className="text">
                  提供京东仓服务与自建仓两种方案，辅助商户实现低成本建设供应链壁垒能力。
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* 核心优势 */}
        <div className="core_advantage">
          <p className="title_big">产品功能/核心优势</p>
          <div
            className="card_top_wrapper"
            onMouseEnter={stopInterval}
            onMouseLeave={() => {
              if (!isClicked) {
                startInterval();
              }
            }}
          >
            <div className="wrapper tabs_wrapper">
              {topTabList.map((item) => {
                return (
                  <div
                    key={item.key}
                    className="card_item"
                    onClick={() => {
                      handleChange(item.key, item.icon, item.imgUrl);
                      setIsClicked(true);
                      clearInterval(intervalRef.current);
                    }}
                  >
                    <div className="icon">
                      <img
                        width={40}
                        height={40}
                        src={`https://static.hiboos.com/static/yzt-home/frontWarehouse/${item.icon}.png`}
                        alt=""
                      />
                    </div>
                    <div
                      className={`content ${
                        tabsIcon == item.icon ? "content_active" : ""
                      }`}
                    >
                      {item.label}
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              className={`card_content ${
                tabActive <= 7 ? "active" : "inactive"
              }`}
            >
              <img
                height={600}
                src={`https://static.hiboos.com/static/yzt-home/frontWarehouse/${tabImgUrl}.png`}
                alt=""
              />
            </div>
          </div>
          <div
            className="card_bottom_wrapper"
            onMouseEnter={stopInterval}
            onMouseLeave={() => {
              if (!isClicked) {
                startInterval();
              }
            }}
          >
            <div className="wrapper tabs_wrapper">
              {bottomTabList.map((item) => {
                return (
                  <div
                    key={item.key}
                    className="card_item"
                    onClick={() => {
                      handleChange(item.key, item.icon, item.imgUrl);
                      setIsClicked(true);
                      clearInterval(intervalRef.current);
                    }}
                  >
                    <div className="icon">
                      <img
                        width={40}
                        height={40}
                        src={require(`@/images/frontWarehouse/${item.icon}.png`)}
                        alt=""
                      />
                    </div>
                    <div
                      className={`content ${
                        tabsIcon == item.icon ? "content_active" : ""
                      }`}
                    >
                      {item.label}
                    </div>
                  </div>
                );
              })}
            </div>
            {/* {tabActive > 7 && (tabActive != 8 && tabActive != 10 && tabActive != 12) ? ( */}
            <div
              className={`card_content ${
                tabActive > 7 ? "active" : "inactive"
              }`}
            >
              {isActive ? (
                <div>
                  <img
                    height={600}
                    src={`https://static.hiboos.com/static/yzt-home/frontWarehouse/${tabImgUrl}.png`}
                    alt=""
                  />
                </div>
              ) : null}
              {tabActive == 8 ? (
                <div className={`card_content_finance`}>
                  <p className="title_big">财务管理数字化</p>
                  <p className="title_small">
                    及时获取关键经营数据、及时识别经营中的问题并采取行动、提升管理效率
                  </p>
                  <div className="main_content">
                    <div className="left_content">
                      <div className="top"></div>
                      <div className="center"></div>
                      <div className="bottom"></div>
                    </div>
                    <div className="right_content"></div>
                  </div>
                </div>
              ) : null}
              {tabActive == 10 ? (
                <div className="card_content_shop">
                  <p className="title_big">巡店管理</p>
                  <p className="title_small">
                    事件过程全记录，问题有出处、过程有记录、结果可查询
                  </p>
                  <div className="main_content">
                    <div className="left_content">
                      <img
                        width={485}
                        height={380}
                        src={
                          "https://static.hiboos.com/static/yzt-home/frontWarehouse/shop_manage_left.png"
                        }
                        alt=""
                      />
                    </div>
                    <div className="right_content">
                      <div className="left"></div>
                      <img
                        style={{ margin: "0 18px" }}
                        width={30}
                        height={192}
                        src={
                          "https://static.hiboos.com/static/yzt-home/frontWarehouse/service_standard_arrow.png"
                        }
                        alt=""
                      />
                      <div className="center"></div>
                      <img
                        style={{ margin: "0 18px" }}
                        width={30}
                        height={192}
                        src={
                          "https://static.hiboos.com/static/yzt-home/frontWarehouse/service_standard_arrow.png"
                        }
                        alt=""
                      />
                      <div className="right"></div>
                    </div>
                  </div>
                </div>
              ) : null}
              {tabActive == 12 ? (
                <div className="card_content_center_warehouse">
                  <p className="title_big">店内/中心仓作业流程数字化</p>
                  <p className="title_small">
                    为商家提供出、入、盘点、报损等业务流程成本效能最优抓手
                  </p>
                  <div className="main_content">
                    <div
                      className={`image-div ${
                        isLeftImageLarge ? "left_spread" : "left_fold"
                      }`}
                      onMouseOver={() => setIsLeftImageLarge(true)}
                    />
                    <div
                      className={`image-div ${
                        isLeftImageLarge ? "right_fold" : "right_spread"
                      }`}
                      onMouseOver={() => setIsLeftImageLarge(false)}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* 服务标准 */}
        <div className="service_standard">
          <div className="wrapper">
            <p className="title_big">专业客户团队 提供一站式服务标准</p>
            <p className="title_small">
              服务14000+合作伙伴，帮助数千企业实现了管理的信息化
            </p>
            <div className="main_content">
              <img
                width={298}
                height={362}
                src={
                  "https://static.hiboos.com/static/yzt-home/frontWarehouse/service_standard_left.png"
                }
                alt=""
              />
              <img
                style={{ margin: "0 18px" }}
                width={30}
                height={192}
                src={
                  "https://static.hiboos.com/static/yzt-home/frontWarehouse/service_standard_arrow.png"
                }
                alt=""
              />
              <img
                width={298}
                height={362}
                src={
                  "https://static.hiboos.com/static/yzt-home/frontWarehouse/service_standard_center.png"
                }
                alt=""
              />
              <img
                style={{ margin: "0 18px" }}
                width={30}
                height={192}
                src={
                  "https://static.hiboos.com/static/yzt-home/frontWarehouse/service_standard_arrow.png"
                }
                alt=""
              />
              <img
                width={298}
                height={362}
                src={
                  "https://static.hiboos.com/static/yzt-home/frontWarehouse/service_standard_right.png"
                }
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="customer_case">
          <p className="title_big">客户案例</p>
          <p className="title_small">
            某闪电仓商家：全国区域约100家门店，品牌为自营+加盟性质，仓内商品单店月销10000单以上
          </p>
          <img
            height={541}
            src={
              "https://static.hiboos.com/static/yzt-home/frontWarehouse/customer_case_main.png"
            }
            alt=""
          />
        </div>
        <div className="partner_wrapper">
          <div className="wrapper">
            <p className="title_big">部分优秀合作伙伴</p>
            <div className="item_wrapper">
              {/* {partnerList.map(key => {
              return (
                <img key={key} width={112} height={112} src={`https://static.hiboos.com/static/yzt-home/frontWarehouse/${key}.png`} alt="" />
              )
            })} */}
              <img
                width={"100%"}
                src={require("@/images/supermarket/partner_list.png")}
                alt=""
              />
            </div>
          </div>
        </div>
        <Apply />
        <Footer />
      </div>
    </div>
  );
};
export default FrontWarehouse;
