import React from 'react';
import Main from './components/Main';
import './index.scss';

const HomePage = () => {
    return (
        <div className='home-page-wrapper'>
            <Main />
        </div>
    )
}
export default HomePage
