import React from 'react';
import Main from './components/Main';
import './index.scss';

const About = () => {
    return (
        <div className='about-wrapper'>
            <Main />
        </div>
    )
}
export default About
