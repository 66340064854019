import { GLOBAL_CHANNEL_ORG, CONFIG_ORG_GLOBAL, USER_INFO, USER_ROLE ,USER_STATION_RELATED, ORG_STRONG_REMINDER_INFO} from './localStorageName';
import { STATION_LIST } from './sessionStorageName';
import { message } from 'antd';
import { EXPORT_TIP } from './Contant';
import {
  getCurrentUserRelateAPI,
  checkRemoteAPI
} from '@/api';

import moment from 'moment';
const isEqualWith = require('lodash/isEqualWith');
function getUserInfo() {
  let result = {};
  let userInfo = localStorage.getItem(USER_INFO);

  if (userInfo) {
    try {
      result = JSON.parse(userInfo);

      result['isSupperAdmin'] = result.specialFlag == 2;
    } catch (e) {
      return result;
    }
  }
  return result;
}
/**
 * 得到商家信息
 */
function getOrgGlobal() {
  let result = {};
  let orgGlobal = localStorage.getItem(CONFIG_ORG_GLOBAL);

  if (orgGlobal) {
    try {
      result = JSON.parse(orgGlobal);
      result['isGroup'] = result['groupFlag'] == '1'; //是否为集团
      result['isErpBoolean'] = result['isErp'] == '1'; //对接ERP
      result['isErpSingleBoolean'] = result['caller'] == '4'; //只对接ERP
      result['isInterior'] = result['caller'] == '3'; //到家内部接口
      result['isErpBooleanAndChannel'] = result['caller'] == '2'; //或者ERP+渠道开放平台
      result['isSyncRatio'] = result['isSyncRatio'] == '1';
      result['isVipBoolean'] = result['isVip'] == '1';
      result['isCollocatBoolean'] = result['isCollocat'] == '1' && result['businessType'] == '2'; //商家是否托管
      result['isChannelPriceBoolean'] = result['isChannelPrice'] == '1';
      result['isVipPromotionErpBoolean'] = result['isVipPromotionErp'] == '1';
      result['isVerificationErpBoolean'] = result['isVerification'] == '1'; // 是否核销
      result['isMmemberPriceBoolean'] = result['isMmemberPrice'] == '1'; // 是否对接会员价
      result['isProductDepreciateErpBoolean'] = result['isProductDepreciateErp'] == '1'; // 商品直降是否对接ERP
      result['isChannelFrontCategory'] = result['isChannelFrontCategory'] == '1'; //是否启分渠道
      result['hiBoFrontCategory'] = result['hiBoFrontCategory'] || -1;
      let basicConfigs = result.basicConfigs || [];
      basicConfigs.map(item => {
        if (item['systemParamId'] === 'isProductDepreciateFdErp') {
          result['isProductDepreciateFdErpBoolean'] = item['systemParamValue'] == '1'; // 商品满减是否对接ERP
        } else if (item['systemParamId'] === 'ifUseFrontCategoryThree') {
          result['ifUseFrontCategoryThreeBoolean'] = item['systemParamValue'] == '1'; //是否启用三级前台类目
        } else if (item['systemParamId'] === 'flyEuserful') {
          result['isSupportFlyEBoolean'] = item['systemParamValue'] == '1'; //是否支持飞蛾打印
        } else if (item['systemParamId'] === 'XinYeYun') {
          result['isSupportXinYeBoolean'] = item['systemParamValue'] == '1'; //是否支持芯烨打印
        } else if (item['systemParamId'] === 'isUseDeliveryTypeELM') {
          result['isUseDeliveryTypeELMBoolean'] = item['systemParamValue'] == '1'; //是否启用饿了么促销生效的订单类型
        } else if (item['systemParamId'] === 'ifVenderShareOldDate') {
          result['ifVenderShareOldDateBoolean'] = item['systemParamValue'] == '1'; //供应商分摊方案是否支持历史日期录入
        } else if (item['systemParamId'] === 'operationDiscountZY') {
          result['ifOperationDiscountZYBoolean'] = item['systemParamValue'] == '1'; //直降促销启用自有渠道
        } else if (item['systemParamId'] === 'operationProductDepreciateZY') {
          result['ifOperationProductDepreciateZYBoolean'] = item['systemParamValue'] == '1'; //满减促销启用有赞渠道
        }
        else if (item['systemParamId'] =='standardProductCoefficient') {
          result['standardProductCoefficientBoolean'] = item['systemParamValue'] == '1'; //标品转换系数	
        }
        else if ( item['systemParamId'] =='onlineAndofflineSalesStatus') {
          result['onlineAndofflineSalesStatusBoolean'] = item['systemParamValue'] == '1'; //线上售卖状态是否受限于线下售卖状态 0 是  1否	
        }
        else if ( item['systemParamId'] =='startCategoryCalculation') {
          result['startCategoryCalculationBoolean'] = item['systemParamValue'] == '1'; //是否开启品类促销智能定价 1=开启，2=关闭	
        }
          else if ( item['systemParamId'] =='ownChannelPromotion') {
          result['ownChannelPromotionBoolean'] = item['systemParamValue'] == '1'; //是否开启自有渠道直降促销 1=开启，2=关闭
        } //windowSecondaryConfirmation
        else if ( item['systemParamId'] =='windowSecondaryConfirmation') {
          result['windowSecondaryConfirmation'] = item['systemParamValue'] == '1'; //编辑转换系数是否二次确认提醒，如配置“是”页面编辑转换系数时会二次确认提醒 0 否  1是	
        } 

        else if ( item['systemParamId'] =='mtBarCodeNonCompliance') {
          result['mtBarCodeNonComplianceBoolean'] = item['systemParamValue'] == '0'; //条码不合规范是否同步美团外卖          0 是  1否	
        }
        else if ( item['systemParamId'] =='djBarCodeNonCompliance') {
          result['djBarCodeNonComplianceBoolean'] = item['systemParamValue'] == '0'; //条码不合规范是否同步京东到家          0 是  1否	
        }

        

        else if ( item['systemParamId'] =='ratioSwitch') {
          result['ratioSwitchBoolean'] = item['systemParamValue'] == '1'; //加价率开关配置 1开 2关	
        }

        else if ( item['systemParamId'] =='stationGroupQuery') {
          result['stationGroupQueryBoolean'] = item['systemParamValue'] == '1'; //门店分组查询 1开 0关	
        }
        
        else if ( item['systemParamId']  =='setStopSellingReason') {
          result['stopSellingReasonBoolean'] = item['systemParamValue'] == '1'; //设置停售原因开关	 1开 0关	
        }
        else if ( item['systemParamId'] =='updateStandardProInactivity') {
          result['updateStandardProInactivity'] = item['systemParamValue'] 
          //1=允许修改,且修改后不自动停用直降促销,2=允许修改,且修改后自动停用直降活动,3=活动中不允许修改,且不自动停用直降活动
        }
        else if ( item['systemParamId'] =='updateNonstandardProInactivity') {
          result['updateNonstandardProInactivity'] = item['systemParamValue'] 
          //1=允许修改,且修改后不自动停用直降活动,2=允许修改,且修改后自动停用直降活动,3=活动中不允许修改,且不自动停用直降活动	
        }
        else if ( item['systemParamId'] =='subChannelManagementPrice') {
          result['subChannelManagementPriceBoolean'] = item['systemParamValue']  ==1;
          //0=关闭,1=开启
          // 1、当开关由开启改为关闭时，系统会自动取消指定价（即：恢复海博门店售价如接收ERP线下价状态会恢复线下价） 2、当开关由关闭改为开启时，可在海博中直接指定经营渠道价 或者取消指定经营渠道价格
        }
        else if ( item['systemParamId'] =='turnYztdelivery') {
          result['turnYztdeliveryBoolean'] = item['systemParamValue']  ==1;
          //是否允许平台配送转配送中台
        }
      });
    } catch (e) {
      result['isErpBoolean'] = false;
      result['isSyncRatio'] = false;
      result['isVipBoolean'] = false;
      result['isCollocatBoolean'] = false; //商家是否托管
      result['isChannelPriceBoolean'] = false;
      result['isVipPromotionErpBoolean'] = false;
      result['isVerificationErpBoolean'] = false;
      result['isMmemberPriceBoolean'] = false;
      result['isProductDepreciateErpBoolean'] = false;
      result['isProductDepreciateFdErpBoolean'] = false;
      result['ifUseFrontCategoryThreeBoolean'] = false; //是否启用三级前台类目
      result['isSupportFlyEBoolean'] = false;
      result['isSupportXinYeBoolean'] = false;
      result['isChannelFrontCategory'] = false;
      result['isUseDeliveryTypeELMBoolean'] = false; // 是否启用饿了么促销生效的订单类型
      result['hiBoFrontCategory'] = -1;
      result['ifVenderShareOldDateBoolean'] = false;
      result['ifOperationDiscountZYBoolean'] = false;
      result['ifOperationProductDepreciateZYBoolean'] = false;
      result['standardProductCoefficientBoolean'] = false;
      result['startCategoryCalculationBoolean'] = false;
      result['ownChannelPromotionBoolean'] = false;
      result['mtBarCodeNonComplianceBoolean'] = false;
      result['djBarCodeNonComplianceBoolean'] = false;
      result['ratioSwitchBoolean'] = false;
      result['stationGroupQueryBoolean']   = false;
      result['stopSellingReasonBoolean'] = false;
      result['subChannelManagementPriceBoolean'] = false;
      return result;
    }
  } else {
    result['isErpBoolean'] = false;
    result['isSyncRatio'] = false;
    result['isVipBoolean'] = false;
    result['isCollocatBoolean'] = false; //商家是否托管
    result['isChannelPriceBoolean'] = false;
    result['isVipPromotionErpBoolean'] = false;
    result['isVerificationErpBoolean'] = false;
    result['isMmemberPriceBoolean'] = false;
    result['isProductDepreciateErpBoolean'] = false;
    result['isProductDepreciateFdErpBoolean'] = false;
    result['ifUseFrontCategoryThreeBoolean'] = false; //是否启用三级前台类目
    result['isSupportFlyEBoolean'] = false;
    result['isSupportXinYeBoolean'] = false;
    result['isChannelFrontCategory'] = false;
    result['isUseDeliveryTypeELMBoolean'] = false; // 是否启用饿了么促销生效的订单类型
    result['hiBoFrontCategory'] = -1;
    result['ifVenderShareOldDateBoolean'] = false;
    result['ifOperationDiscountZYBoolean'] = false;
    result['ifOperationProductDepreciateZYBoolean'] = false;
    result['standardProductCoefficientBoolean'] = false;
    result['startCategoryCalculationBoolean'] = false;
    result['ownChannelPromotionBoolean'] = false;
    result['mtBarCodeNonComplianceBoolean'] = false;
    result['djBarCodeNonComplianceBoolean'] = false;

   
    result['ratioSwitchBoolean'] = false;


    result['stationGroupQueryBoolean']   = false;
    result['stopSellingReasonBoolean'] = false;
    result['subChannelManagementPriceBoolean'] = false;
  }
  return result;
}

function responseInfo(response, success, fail) {
  if (response.data && response.data.success) {
    let data = response.data;
    success(data, data.result && data.result);
  } else {
    fail && fail(response.data);
  }
}
// 根据商家编码获取全部渠道
function _getChannelByOrg() {
  let result = [];
  let channelOrg = localStorage.getItem('GROUP_GLOBAL_CHANNEL_ORG');

  if (channelOrg) {
    try {
      result = JSON.parse(channelOrg);
    } catch (e) {
      return result;
    }
  }
  return result;
}

function getChannelByOrg() {
  return _getChannelByOrg();
}

// 根据商家编码获取全部渠道list to object 对象
function getChannelByOrgToObject() {
  let result = {};
  let list = _getChannelByOrg();
  list.forEach(item => {
    if (!result[item.channelId]) {
      result[item.channelId] = item;
    }
  });
  return result;
}
/**
 * 根据商家编码获取全部渠道list to map 对象
 */
function getChannelByOrgToMap() {
  let map = new Map();
  let list = _getChannelByOrg();
  list.forEach(item => {
    map.set(item.channelId, item);
  });
  return map;
}

function getChannelByChannelCodeToObject() {
  let result = {};
  let list = _getChannelByOrg();
  list.forEach(item => {
    if (!result[item.channelCode]) {
      result[item.channelCode] = item;
    }
  });
  return result;
}

function dealPageInfo(pageInfo) {
  return {
    pageNo: pageInfo.pageNo || 1,
    pageSize: pageInfo.pageSize || 10,
    totalCount: pageInfo.totalCount || 0,
  };
}
//
/**
 * 导出成功之后，提示信息，如果没有传则用默认的
 * @param {*} msg
 */
function exportSuccess(msg) {
  message.success(msg || EXPORT_TIP);
}

function createUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/*ALI_PAY(1001, "支付宝"),
    WE_CHAT_PAY(1002, "微信"),
    UNION_PAY(1003, "银联"),
    BALANCE(1101, "钱包/余额"),
    CARD(1201, "储值卡"),
    INTEGRAL(1301, "积分"),
    COUPON(1401, "优惠券"),
    REBATE_COUPON(1402, "抵扣券"),
    OTHERS(9999, "其他"),*/
function getPayType(type) {
  let detail = '';
  switch (type) {
    case 1001:
      detail = '支付宝';
      break;
    case 1002:
      detail = '微信';
      break;
    case 1003:
      detail = '银联';
      break;
    case 1101:
      detail = '钱包/余额';
      break;
    case 1201:
      detail = '储值卡（不开票）';
      break;
    case 1202:
      detail = '储值卡（开票）';
      break;
    case 1301:
      detail = '积分';
      break;
    case 1401:
      detail = '优惠券';
      break;
    case 1402:
      detail = '抵扣券';
      break;
    case 9999:
      detail = '其他';
      break;
    case 1203:
      detail = '储值卡（周年庆）';
      break;
  }
  return detail;
}
function getStationList() {
  let result = [];
  let tempStationList = sessionStorage.getItem(STATION_LIST);
  try {
    result = JSON.parse(tempStationList);
  } catch (e) {
    result = [];
  }
  return result;
}
function getStationNames() {
  let result = [];

  let stationList = getStationList() || [];
  console.log(stationList);
  stationList.forEach(item => {
    result.push(item.stationName);
  });
  return result.length > 0 ? result.join(',') : '';
}

function getUserRole() {
  let result = [];

  let tempUserRoleList = localStorage.getItem(USER_ROLE);
  if (tempUserRoleList) {
    try {
      result = JSON.parse(tempUserRoleList);
    } catch (e) {
      result = [];
    }
  }
  return result;
}

function getCurrentUserRoleNames() {
  let roleNames = [];

  getUserRole().forEach((item, index) => {
    roleNames.push(item.name);
  });
  return roleNames.length > 0 ? roleNames.join(',') : '';
}

function grayOrgInfo() {
  let printGrayOrgCodeList = ['111261'];

  let orgInfo = getOrgGlobal();

  let _index = printGrayOrgCodeList.findIndex(code => {
    return code == orgInfo.orgCode;
  });

  console.log(_index != -1);

  return {
    handMovementToAuto: _index != -1, //手动打印转自动打印的灰度商家
    // handMovementToAuto:false,
  };
}
 function   getUserStationRelated () {

  let userStationRelated  = {};

  try{

    if ( localStorage.getItem(USER_STATION_RELATED)) {
      userStationRelated = JSON.parse(localStorage.getItem(USER_STATION_RELATED));
    }
    else {
     
      GetCurrentUserRelateAPI(userStationRelated);
    }
  }catch (e) {
    return userStationRelated;
  }
  return userStationRelated;
}

async function GetCurrentUserRelateAPI(userStationRelated) {
  let rs = await  getCurrentUserRelateAPI();

  responseInfo ( rs,(data,rt)=>{
   userStationRelated = rt||[];
   localStorage.setItem(USER_STATION_RELATED, JSON.stringify(rt || {}));
  })
}
/**
 * js数字转中文
 * @param {*} num 
 * @returns 
 */
function toChinesNum (num) {
  let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  let unit = ["", "十", "百", "千", "万"];
  num = parseInt(num);
  let getWan = (temp) => {
  　　let strArr = temp.toString().split("").reverse();
  　　let newNum = "";
  　　for (var i = 0; i < strArr.length; i++) {
    　　newNum = (i == 0 && strArr[i] == 0 ? "" : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? "" : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum;
  　　}
   　 return newNum;
 }
 let overWan = Math.floor(num / 10000);
 let noWan = num % 10000;
 if (noWan.toString().length < 4) {　　　　　　noWan = "0" + noWan;　　　 }
 return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
}

function getOrgStrongReminderInfoList () {
  let orgStrongReminderInfo  = [];
  try{
    if (localStorage.getItem(ORG_STRONG_REMINDER_INFO)) {
      orgStrongReminderInfo = JSON.parse(localStorage.getItem(ORG_STRONG_REMINDER_INFO));
    }
  }catch (e) {
    return orgStrongReminderInfo;
  }
  return orgStrongReminderInfo;
}


 function msToFormat (startTime,endTime) {
  let t1 = moment(endTime);
  let t2 = moment(startTime);
  let diffTime = t1 - t2;
  // 将分钟转换为 天,时,分,秒
  if (diffTime>0) {
    let t = parseFloat(diffTime / 1000); // 此处得到总秒数
    let d, h, m, s; // 定义时分秒的变量（天,时,分,秒）
    d = Math.floor(t / (24 * 3600)); // 计算 天数 // 计算已经减去的天数的总秒数
    t = t - d * 24 * 3600;
    h = Math.floor(t / 3600); // 计算 小时数 // 计算已经减去的小时数的总秒数
    t = t - h * 3600;
    m = Math.floor(t / 60); // 计算 分钟数 // 计算已经减去的分钟数的总秒数（实际上从上往下，这里已经的t已经是减去了小时秒数的值）
    t = t - m * 60;
    s = t; // 计算 秒钟数
    return d + '天' + h + '小时' + m + '分钟' + s + '秒';
  }
  return '-';
};
// 下载模板公共路径
function getDownTplComUrl(){
  return  'https://storage.jd.com/yzt-file/'
}
function customizer(objValue, othValue) {
  // 如果是数组走自定义判断
  if (Array.isArray(objValue) && Array.isArray(othValue)) {
    console.log(objValue)
    if (objValue.length == othValue.length) {
      // 
      let flag = true;
      objValue.forEach((item) => {
        if (item.hasOwnProperty('attrId')) {
          let othValueFind = othValue.find((innerItem)=>{return innerItem.attrId == item.attrId});
          console.log(othValueFind)
          if (!othValueFind) {
            flag =  false;
            return; // 结束for循环
          }
          // 对比valueList
          flag = isObjectEqual(item.valueList,othValueFind.valueList);
          // valueList 不一样直接结束循环
          if (!flag) {
            return
          }
        } else {
          console.log(othValue.find((innerItem)=>{return (innerItem.valueId == item.valueId&&innerItem.value == item.value)}))
          if (!othValue.find((innerItem)=>{return (innerItem.valueId == item.valueId&&innerItem.value == item.value)})) {
            flag =  false;
            return; // 结束for循环
          }
        }
      })
      return flag;
    } else { 
      return false;
    }
  }
}
// var a = { 'a': 1, 'b': { 'c': [{name: '张三',id: 1}, {name: '张三',id: 2},{name: '张三',id: 3}]} };
// var b = { 'a': 1, 'b': { 'c': [{name: '张三',id: 1}, {name: '张三',id: 2},{name: '张三',id: 3}]}, };
// var c = _.isEqualWith(a, b, customizer);
// console.log(c);
// 比较两个对象值是否相等
const isObjectEqual = (obj1, obj2) => {
  return isEqualWith(obj1, obj2, customizer);
}

export {
  msToFormat,
  getUserStationRelated,
  toChinesNum,
  grayOrgInfo,
  getUserRole,
  getCurrentUserRoleNames,
  getStationNames,
  getStationList,
  createUUID,
  getOrgGlobal,
  responseInfo,
  getUserInfo,
  getChannelByOrg,
  getChannelByOrgToObject,
  getChannelByChannelCodeToObject,
  dealPageInfo,
  exportSuccess,
  getPayType,
  getOrgStrongReminderInfoList,
  getDownTplComUrl,
  isObjectEqual
};
