import React, { useRef, useState } from "react";
import Apply from "@/components/Apply";
import Footer from "@/components/Footer";
import ApplyModal from "@/components/ApplyModal";
import { Modal } from "@hibo/HiboBiz";
import { CloseOutlined } from "@ant-design/icons";

const NoWarehouse = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const videoRef = useRef(null);

  const modalShow = () => {
    if (!showModal) {
      setShowModal(true);
    }
  };
  const onCancel = () => {
    setShowModal(false);
  };
  const showVideoModal = () => {
    setIsModalVisible(true);
    document.body.style.overflow = "hidden"; // 禁用页面滚动
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // 重置视频播放时间
      videoRef.current.play(); // 重新播放视频
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    document.body.style.overflow = "auto"; // 恢复页面滚动
    if (videoRef.current) {
      videoRef.current.pause(); // 暂停视频播放
    }
  };
  const handleVideoLoaded = () => {
    setIsVideoLoaded(true);
  };

  return (
    <div className="no_warehouse_wrapper">
      <ApplyModal isShow={showModal} onCancel={onCancel} />
      <Modal
        title={null}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null} // 不显示默认的底部按钮
        width="60%" // 设置模态框的宽度
        style={{ top: 20 }} // 设置模态框距离顶部的距离
        // bodyStyle={{ padding: 0 }} // 去除模态框内容的内边距
        bodyStyle={{ padding: 0, lineHeight: 0 }} // 去除模态框内容的内边距
        centered
        closable={true} // 显示右上角的关闭按钮
        closeIcon={
          <CloseOutlined style={{ color: "#FFFFFF", fontSize: "24px" }} />
        } // 自定义关闭按钮的图标
      >
        {/* {!isVideoLoaded && (
          <div className="video-placeholder">
            <p>正在加载...</p>
          </div>
        )} */}
        <video
          ref={videoRef}
          controls
          autoPlay
          className="modal-video"
          style={{
            width: "100%",
            height: "auto",
            // display: isVideoLoaded ? "block" : "none",
          }}
          onLoadedData={handleVideoLoaded}
        >
          <source
            // src="https://static.hiboos.com/static/yzt-home/noWarehouse/no_warehouse.mp4"
            src={require(`@/images/noWarehouse/no_warehouse.mp4`)}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </Modal>

      <div className="carousel_wrapper_front">
        <img
          style={{ width: "100%" }}
          src={`https://static.hiboos.com/static/yzt-home/noWarehouse/no-warehouse.webp`}
          alt=""
        />
        <p className="opt_btn" onClick={modalShow}>
          免费咨询
        </p>
        <p className="opt_video" onClick={showVideoModal}></p>
      </div>
      {/* <div className="banner">
        <img
          style={{ width: "100%" }}
          src={`https://static.hiboos.com/static/yzt-home/noWarehouse/no_warehouse.mp4`}
          // src={require('@/images/homePage/take_out.webp')}
          alt=""
        />
        <div className="banner-overlay">
          <h1>无人仓</h1>
          <button onClick={showVideoModal}>查看视频</button>
        </div>
      </div> */}

      <div className="work_scene">
        {/* 解决方案 */}
        <div className="store_rx">
          <div style={{ margin: "0 50px" }}>
            <p className="title_big">无人仓系统解决方案</p>
            <div className="main_content">
              <img
                width={1153}
                height={438}
                src={require(`@/images/noWarehouse/store_rx.png`)}
                alt=""
              />
            </div>
          </div>
        </div>
        {/* business */}
        <div className="no_warehouse_scene">
          <p className="title_big">无人仓系统场景</p>
          <div className="main_content">
            <div className="scene_bg">
              <img
                width={1209}
                height={679}
                src={require(`@/images/noWarehouse/no_warehouse_scene.png`)}
                alt=""
              />
              <div
                className="sceneHover1"
                onMouseEnter={() => setHover1(true)}
                onMouseLeave={() => setHover1(false)}
              >
                <span className="scene_person1" />
                <span className="scene_gif1" />
              </div>
              <img
                width={306}
                height={154}
                className={`scene_hover1 ${hover1 ? "show" : ""}`}
                src={require(`@/images/noWarehouse/scene_hover1.png`)}
                alt=""
              />
              <div
                className="sceneHover2"
                onMouseEnter={() => setHover2(true)}
                onMouseLeave={() => setHover2(false)}
              >
                <span className="scene_person2" />
                <span className="scene_gif2" />
              </div>
              <img
                width={306}
                height={171}
                className={`scene_hover2 ${hover2 ? "show" : ""}`}
                src={require(`@/images/noWarehouse/scene_hover2.png`)}
                alt=""
              />
            </div>
          </div>
        </div>
        {/* 核心优势 */}
        <div className="order_complete_flow">
          <p className="title_big">订单全流程</p>
          <p className="title_small">
            自动接单、呼叫骑手、骑手自助取货送货，实现外卖24h无人销售
          </p>
          <div className="main_content">
            <img
              width={1174}
              height={596}
              src={require(`@/images/noWarehouse/order_complete_flow.png`)}
              alt=""
            />
          </div>
        </div>
        {/* 客户案例 */}
        <div className="customer_case">
          <p className="title_big">客户案例</p>
          <p className="title_small">
            某成人用品无人闪电仓，门店数量30+，25～30平米，800+SKU，24小时营业
          </p>
          <img
            height={475}
            src={require(`@/images/noWarehouse/customer_case_main.png`)}
            alt=""
          />
        </div>
        <Apply />
        <Footer />
      </div>
    </div>
  );
};
export default NoWarehouse;
