// import OrderManage from '@/container/OrderManage'
// import OrderPrintNew from '@/container/Print/OrderPrintNew'
// import OrderManage from '@/container/OrderManage/List'
import { createHashRouter, Navigate } from 'react-router-dom';
// import Haibo from '@/container/Haibo';
import Supermarket from '@/container/SuperMarket';
import HomePage from '@/container/HomePage';
import FrontWarehouse from '@/container/FrontWarehouse';
import Takeout from '@/container/TakeoutPage';
import NoWarehouse from '@/container/NoWarehouse';
import About from '@/container/About';
import UserLayout from '@/layouts/userLayout';


const routes = createHashRouter([
  {
    path: "/",
    element: <UserLayout />,
    children: [
      {
        path: "/", // 设置为默认路由
        element: <Navigate to="/home" replace />, // 重定向到 /home
      },
      {
        path: "/home",
        element: <HomePage />,
      },
      {
        path: "/supermarkets",
        element: <Supermarket />
      },
      {
        path: "/front-warehouse",
        element: <FrontWarehouse />
      },
      {
        path: "/take-out-v",
        element: <Takeout />
      },
      {
        path: "/no-warehouse",
        element: <NoWarehouse />
      },
      {
        path: "/about",
        element: <About />
      },
    ]
  },
],
  // {
  //   basename: window.__POWERED_BY_QIANKUN__ ? '/xcosOrderNew' : '', // 微前端应用的基路径
  // }
);

export default routes