import React, { useState } from 'react'
import ApplyModal from '@/components/ApplyModal';
import './index.scss';
const Apply = () => {
  const [showModal, setShowModal] = useState(false)

  const modalShow = () => {
    if (!showModal) {
      setShowModal(true)
    }
  }
  const onCancel = () => {
    setShowModal(false)
  }

  return (
    <div className='apply_wrapper'>
      <div className='wrapper'>
        <ApplyModal isShow={showModal} onCancel={onCancel} />
        <p className='title_big'>马上使用海博即时零售OS 让生意增长快速发生</p>
        <div className='btn_wrapper'>
          <span className='btn' onClick={modalShow}>免费咨询</span>
        </div>
      </div>
    </div>
  );
}
export default Apply
