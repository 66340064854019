import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Toast extends Component {
    static propTypes = {
        msg: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
        duration: PropTypes.number,
        customClass: PropTypes.string,
        willUnmount: PropTypes.func,
        icon: PropTypes.string
    }

    static defaultProps = {
        duration: 1000,
        customClass: '',
        willUnmount: null,
        icon: ''
    }

    constructor(props) {
        super(props)

        this.state = {
            visible: false
        }
    }

    componentDidMount() {
        this.setState({
            visible: true
        })
        this.startTimer()
    }

    onClose() {
        this.stopTimer()
        this.setState({
            visible: false
        })
        // setTimeout(() => {
        // 	this.props.willUnmount();
        // }, 1000);
        this.toastTable.addEventListener('transitionend', () => {
            this.props.willUnmount()
        })
    }

    startTimer() {
        if (this.props.duration > 0) {
            this.timeout = setTimeout(() => {
                this.onClose()
            }, this.props.duration)
        }
    }

    stopTimer() {
        clearTimeout(this.timeout)
    }

    renderIcon() {
        const icon = this.props.icon
        //未关注icon
        return <i className={icon} />
    }

    render() {
        const { customClass, msg } = this.props
        return (
            <table
                ref={e => {
                    this.toastTable = e
                }}
                className={`vertical-middle-msg ${!this.state.visible ? 'vertical-middle-msg-hide' : ''}`}
            >
                <tbody>
                    <tr>
                        <td className="ver-m-td">
                            <div className={'msg-content ' + customClass}>
                                {this.renderIcon()}
                                {msg}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
}
