import React, { useState, useEffect } from 'react';
import './index.scss';

const BrandList = (props) => {
  const { idname = 'brandList', styles, imgsrc } = props;
  
  useEffect(() => {
    var dom = document.getElementById('brandList');
    var timer = null;
    var len = dom.offsetWidth;
    dom.innerHTML += dom.innerHTML;
    dom.style.width = len * 2 + 'px';
    function slider() {
      dom.style.left = dom.offsetLeft - 1 + 'px';
      if (dom.offsetLeft < -dom.offsetWidth / 2) {
        dom.style.left = '0';
      }
    }
    timer = setInterval(slider, 10);
    dom.onmouseover = function () {
      clearInterval(timer);
    }
    dom.onmouseout = function () {
      timer = setInterval(slider, 10);
    }
  }, [])

  return (
    <div className="brandList-wrap">
      <div id={idname} className="brand-list-box">
        <img src={imgsrc} alt='' className="brandListImg" style={{ ...styles }} />
      </div>
      <div className="flur-before"></div>
      <div className="flur-after"></div>
    </div>
  )
}
export default BrandList;
