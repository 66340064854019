import axios from 'axios';
import qs from 'qs';
import md5 from '../common/md5.js';
let signKey = '923047ae3f8d11d8b19aeb9f3d1bc200'


axios.interceptors.request.use(config => {
    if (config.url.startsWith('/ops')) {
        config.url = `https://gw.hiboos.com${config.url}`;
    }
    if (config.url.startsWith('/landing')) {
        config.url = `https://zt.hiboos.com${config.url}`;
    }
    return config;
});
// 拦截响应response，并做一些错误处理
axios.interceptors.response.use(
    resp => {
        const data = resp.data || resp.resp;
        if (data.success || data.code === 0 || data.code === 9) {
            return Promise.resolve({
                result: data.result,
                resp: data
            });
        } else {
            return Promise.reject(data);
        }
    },
    err => {
        // 这里是返回状态码不为200时候的错误处理
        if (err && err.response) {
            switch (err.response.status) {
                default:
                    err.message = '请求失败';
                    break;
            }
        }
        return Promise.reject(err);
    }
);

// 默认为GET请求
// 取key时，若两个请求data部分完全相同，
// type却不同，一个为POST一个为GET，且都要求cache，那么key会混淆，但一般不会存在这种情况
// timeout 超时设置， 个别接口需要设置超时，提单和在线支付需要超时比较长（60000）一分钟
// 公共参数
const commonParams = {
    platCode: 'H5',
    appName: 'djpc',
    channel: ''
};


export function api_ajax1(data, type, opt) {
    const apiPath = '/client';
    data.body = Object.assign(data.body);
    data.body = JSON.stringify(data.body);
    //可以在body中覆盖公共参数
    const params = { ...commonParams, ...data };
    params.signKey = data.body ? md5(data.body + signKey) : md5(signKey);

    typeof type == 'object' && (opt = type);
    let key;
    if (opt && opt.cache) {
        key = JSON.stringify(params);
    }


    return (
        new Promise((resolve, reject) => {
            // console.log('====================================');
            // console.log(type, type = 'POST' ? undefined: params);
            // console.log('=====================================');
            axios({
                method: type || 'GET',
                url: `${apiPath}?_jdrandom=` + new Date().getTime(),
                params: type === 'POST' ? undefined : params,
                data: qs.stringify(params),
                timeout: params.timeout || 9999
            })
                .then(res => {
                    resolve(res || {});
                })
                .catch(error => {
                    error = error || {};
                    reject(error || {});
                });
        })
    );
}
export default axios
