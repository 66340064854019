import React, { useState, useEffect } from 'react'
import ApplyModal from '@/components/ApplyModal';
import { usePhoneNumberState } from '@/utils/PhoneNumberContext';
import './index.scss';
const Connect = () => {
  const [showModal, setShowModal] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const { phoneNumber, setPhoneNumber } = usePhoneNumberState()

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 500) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener('scroll', toggleVisibility)
    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [])

  // 定义滚动到顶部的函数
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // 平滑滚动
    });
  };

  const modalShow = () => {
    if (!showModal) {
      setShowModal(true)
    }
  }

  const onCancel = () => {
    setShowModal(false)
  }

  return (
    <div className='fixed_wrapper'>
      <ApplyModal isShow={showModal} onCancel={onCancel} />
      <div className='fixed_wrapper_content'>
        <p className='title'>微信咨询</p>
        <img height={90} src={require('@/images/haiboConnectImgNew.png')} alt="" />
        <div className='service_title'>
          <img height={14} src={require('@/images/header_phone.gif')} alt="" />
          <span>客服热线</span>
        </div>
        <p className='phone_number'>{phoneNumber}</p>
        <div className='try_btn_wrapper'>
          <span className='try_btn' onClick={modalShow}>免费咨询</span>
        </div>
      </div>
      {isVisible ? (
        <div className='to_top_wrapper' onClick={scrollToTop}>
          <img height={18} src={require('@/images/to_top_icon.png')} alt="" />
          <span>回到顶部</span>
        </div>
      ) : null}

    </div>
  );
}
export default Connect
