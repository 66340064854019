// PhoneNumberContext.js
import React, { createContext, useState, useContext } from 'react';

// 创建 Context
const PhoneNumberContext = createContext();

// 创建 Provider 组件
export const PhoneNumberProvider = ({ children }) => {
  const [phoneNumber, setPhoneNumber] = useState(null);

  return (
    <PhoneNumberContext.Provider value={{ phoneNumber, setPhoneNumber }}>
      {children}
    </PhoneNumberContext.Provider>
  );
};

// 自定义 Hook 方便使用 Context
export const usePhoneNumberState = () => {
  return useContext(PhoneNumberContext);
};