import { RouterProvider } from "react-router-dom"
import routes from './router'
// 导入 根store
import store from './store';
// 导入 react-redux 的内置组件 Provider
import { Provider } from 'react-redux';
import './common/css/reset.css'
import '@hibo/HiboBiz/dist/index.css';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <RouterProvider router={routes} />
      </Provider>
    </div>
  );
}

export default App;
