// 导入创建 store 的方法
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { orderManageCountApi, orderManageListApi } from '@/api/orderQueryAPI';

// 导出
export const fetchAsyceList = createAsyncThunk('fetch/async', async () => {
    const { data } = await axios({
        url: 'https://gw.hiboos.com/ops/customerInfo/selectReserveByPhone',
        method: 'post',
        headers: {
            'X-Forwarded-Device': '0e256dff-eb7b-40af-a21d-480f3652f454',
            'X-Forwarded-Source': 'hibo_pc',
        }
    })
    return data;
})

export const fetchCountStatistics = createAsyncThunk('fetchcount/async', async (queryInfo) => {
    const params = Object.assign({}, queryInfo);
    let response = await orderManageCountApi(params);
    const result = response.data.result || {};
    return result;
})

export const queryAllOrderFun = createAsyncThunk('fetchlist/async', async (param) => {
    const { pageInfo, queryInfo } = param;
    console.log('queryAllOrderAction',pageInfo);
    let response = await orderManageListApi(Object.assign({}, queryInfo), pageInfo);
    const result = response.data.result || {};
    return { data: result.resultList || [], pageInfo:{ maxPageSize: result.maxPageSize, pageNo: result.pageNo, pageSize: result.pageSize, totalCount: result.totalCount}};
})

const orderManageStore = createSlice({
    // 必填项
    name: 'orderManage',

    // 初始状态数据
    initialState: {
        orderListLoading: false,
        orderQueryClick: true,
        orderList: [], //异常单、拣货单list
        orderListOrder: [], //订单查询list
        queryInfo: {},
        orderQueryFormNew: {},
        pageInfo: { pageNo: 1, pageSize: 5 },
        orderDetailAll: {
            orderInfoDTO: {},
            skuInfoList: [],
            operationLogList: [],
        },
        abnormalOrderStatic: [],
        pickOrderStatic: [],
        stockList: [],
        abnormalForm: {},
        printOrderId: [],
        isLoadingList: false,  // 列表加载中
        stationList: [],
        abnormalOrderQueryRefresh: 0,  //异常单切换搜索条件重置

        queryDispatchOrderAmount: 0,//查询派单复核接口
        reviewSoundEnable: false,
        tabIndex: 0,
        quantitativeStatistics: {}, // 汇总数据
    },

    // 修改数据 - 同步方法
    reducers: {
        orderQueryFormNewValueChange(state, { payload }) {
            state.orderQueryFormNew = payload;
        },
        orderListLoadingFun(state, { payload }) {
            state.orderListLoading = payload;
        },
        orderQueryClickFun(state, { payload }) {
            state.orderQueryClick = payload;
        },
        privacyUpdateOrderListFun(state, { payload }) {
            state.orderListOrder = payload;
        },
        setPrintOrderIdFun(state, { payload }) {
            state.printOrderId = payload;
        }
    },
    // 额外的 reducer 执行异步操作
    extraReducers: builder => {
        // 计算属性名 [computedNames]
        // builder.addCase(fetchAsyceList.pending, (state, action) => {
        //     console.log('fetchAsyncList 执行中还未有结果')
        // }),
        builder.addCase(fetchAsyceList.fulfilled, (state, { payload }) => {
            console.log('fetchAsyncList 拿到结果了', payload)
            state.info = payload.result;
        }),
        builder.addCase(fetchAsyceList.rejected, (state, action) => {
            console.log('fetchAsyncList 错误了')
        }),
        builder.addCase(fetchCountStatistics.fulfilled, (state, { payload }) => {
            console.log('fetchAsyncList 拿到结果了', payload)
            state.quantitativeStatistics = payload;
        }),
        builder.addCase(fetchCountStatistics.rejected, (state, action) => {
            console.log('fetchAsyncList 错误了')
            console.log(action.payload)
        }),
        builder.addCase(queryAllOrderFun.fulfilled, (state, {payload}) => {
            console.log('fetchAsyncList 拿到结果了', payload)
            state.orderListOrder = payload.data;
            state.pageInfo = payload.pageInfo;
            state.orderListLoading = false;
            state.orderQueryClick = true;
        })
    }

});

// 解构出创建 action 对象的函数（actionCreater）
// 并以 按需导出 的方式 导出 actionCreater
export const {
    orderQueryFormNewValueChange,
    orderListLoadingFun,
    orderQueryClickFun,
    privacyUpdateOrderListFun,
    setPrintOrderIdFun
} = orderManageStore.actions;

// 以默认导出的方式导出 reducer函数
export default orderManageStore.reducer;
