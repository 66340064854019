import axios from 'axios';
import { message } from 'antd';
import {
  getResDomain,getHeaderInfo
} from '@/utils/envConfig';
import { newApiMap } from '@/utils/newApi';
// axios.defaults.baseURL = getResDomain().baseURL;
// axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.interceptors.request.use(config=>{ 
  if ( config.url.indexOf('https://log-o2o.jd.com/v1/logging')<0){
    // config['withCredentials'] = true;


    // let headerInfo = getHeaderInfo();

    // for( let key in headerInfo) {

    //   config.headers[key] = headerInfo[key];
    // }

  }
 return config;
 },err=>{
   return Promise.reject(err);
 });
axios.interceptors.response.use(
  response => {
    // Do something with response data


    // console.log(response);
    // if (response.data.code == '400401') {
    //   // console.log('====================================');
    //   // console.log(response.data.code, push);
    //   // console.log('====================================');
    //   // push('/user/login');
    //   window.location.href = '#/user/login';
    // }
    // else  if ( response.data.code =='400403'){
    //   message.error(response.data.msg||'');
    // }
    return response;
  },
  error => {
    // Do something with response error
    //message.error('网络异常，稍后再试~');
    // return Promise.reject(error);
  },
);

export {
  userQueryUserListAPI,
  userResetPasswordAPI,
  updateUserAPI,
  userRegisterAPI,
  userSetUserStatusAPI,
  userStationGetAPI,
  userStationAddAPI,
  userStationUpdateAPI,
  getUserRoleRelateListAPI,
  stationroupGetAllAPI,
  userGetUserByUserPinAPI,
  userSendCodeAPI,
  userUpdateCodeAPI,
  sendPhoneCodeAPI,
  sendMobileCodeAPI,
  delUserAPI,
  getCurrentUserRelateAPI,
  userExportAPI,
  erpUserExportAPI,
  checkExternalAccountMandatoryAPI,
} from './userAPI';

