function emptyAction(){
    console.warn("Current execute action is empty!");
  }
  
  class Actions {
    actions = {
      onGlobalStateChange: emptyAction,
      setGlobalState: emptyAction
    }
  
    setActions(actions){
      this.actions = actions;
    }
  
    onGlobalStateChange(...args){
      if(this.actions.onGlobalStateChange){
        return this.actions.onGlobalStateChange(...args);
      }else{
        return () => {};
      }
      // return this.actions.onGlobalStateChange(...args);
    }
  
    setGlobalState(...args){
      if(this.actions.setGlobalState){
        return this.actions.setGlobalState(...args);
      }else{
        return () => {};
      }
      // return this.actions.setGlobalState(...args);
    }
  
  }
  
  const actions = new Actions();
  export default actions;
  
  