import React, { useState, useEffect } from 'react'
import { api_addCustomerInfo } from '@/api/api';
import { Modal, Input } from '@hibo/HiboBiz';
import Toast from '@/components/Toast/index';
import './index.scss';
const ApplyModal = (props) => {
  const { isShow, onCancel } = props
  const [name, setName] = useState('')
  const [userPhone, setUserPhone] = useState('')

  useEffect(() => {
    if (isShow) {
      setName('');
      setUserPhone('');
    }
  }, [isShow])

  const modalHide = () => {
    onCancel && onCancel();
  }

  // 提交
  const submit = () => {
    if (name === '') {
      Toast('用户名为空，请进行填写');
    } else if (userPhone === '') {
      Toast('联系电话为空，请进行填写');
    } else {
      if (/^((1[0-9]{10})|(((([0-9]{3}-)[0-9]{8})|(([0-9]{4}-)[0-9]{7}))))$/.test(userPhone)) {
        api_addCustomerInfo({
          contractPhone: userPhone,
          contractName: name
        }).then(res => {
          Toast('提交成功，工作人员将在48小时内联系您');
          modalHide();
          setName('');
          setUserPhone('');
        }).catch(err => {
          Toast(err.msg || '提交失败，请刷新后重试');
        })
      } else {
        Toast('联系电话格式存在问题，请进行检查');
      }
    }
  }

  return (
    <Modal
      open={!!isShow}
      styles={{
        height: '350px'
      }}
      width='440px'
      closable={true}
      maskClosable={false}
      onOk={submit}
      onCancel={modalHide}
      title='免费咨询'
      wrapClassName='apply_modal_wrapper'
    >
      <div className=''>
        <div className=''>填写信息，客服人员会尽快联系您</div>
        <div className='title'>用户名</div>
        <Input value={name} maxLength={30} placeholder='用户名' onChange={(e) => {
          let value = e.target.value.trim()
          setName(value)
        }} />
        <div className='title'>联系电话</div>
        <Input value={userPhone} placeholder='可输入11位手机号或“区号-号码”的固话号码' onChange={(e) => {
          let value = e.target.value.trim();
          if (/^[0-9-]*$/.test(value) || value === '') {
            setUserPhone(value)
          }
        }} />
      </div>
    </Modal>
  );
}
export default ApplyModal
