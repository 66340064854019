/**
 * 到家埋点
 */

import axios from 'axios';
import Qs from 'qs';

import moment from 'moment';
import {
	RE_PAGE_INFO
} from '@/utils/sessionStorageName';
import { getUserInfo, getOrgGlobal, } from '@/utils/common';
import { detectOS, myBrowser } from '@/utils/formatter';

import {
	getCookie
} from '@/utils/cookieUtil';
import { DJMtaUtils } from '@/utils/Jdmd';

// 消息分页接口
export async function logJDDJAPI(json) {
	return axios({
		url: 'https://log-o2o.jd.com/v1/logging',
		method: 'post',
		data: Qs.stringify({
			logType: 'dj_log_com_action',
			json: JSON.stringify(json),
		}),
	});
}
let Browser = require('@/utils/Browser');
const _ = require('lodash');


export async function mt4Page(pageInfo, isOpen) {

	let commInfo = mtCommon(pageInfo.currentPageName, pageInfo.currentPageCn, pageInfo.ref_page_name, pageInfo.ref_page_url, isOpen);
	let addressIpInfo = JSON.parse(localStorage.getItem('addressIpInfo'));
	let result = { ...commInfo };
	result.log_data_type = "hibo_h5_pv";
	result.click_id = ""
	result.click_par = {};

	result.cur_page_par = {
		...result.cur_page_par, ...pageInfo.url_page_par
	}
	let firstLevelParams = JDMD(pageInfo.currentPageName, pageInfo.currentPageCn, pageInfo.ref_page_name, pageInfo.ref_page_url, isOpen);
	let ext_par = {
		client_ip: addressIpInfo&&addressIpInfo["ip"] ? addressIpInfo["ip"] : '',
		city_id: addressIpInfo&&addressIpInfo["adcode"] ? addressIpInfo["adcode"] : '',
		city_id_pos: addressIpInfo ? (addressIpInfo["province"] == addressIpInfo["city"] ? `${addressIpInfo["city"]}${addressIpInfo["district"]}` : `${addressIpInfo["province"]}${addressIpInfo["city"]}${addressIpInfo["district"]}`) : '',
	}
	DJMtaUtils.sendPvData(firstLevelParams, ext_par, 'site_pv')
	// return logJDDJAPI([result]);
}


/**
 * 埋点click
 */
export async function mtClickBatch(page_name, page_name_cn, ref_page_name, ref_page_url, click_par, url_page_par, clickList) {
	let addressIpInfo = JSON.parse(localStorage.getItem('addressIpInfo'));
	let commInfo = mtCommon(page_name, page_name_cn, ref_page_name, ref_page_url)
	let result = { ...commInfo };
	result.log_data_type = "hibo_h5_click";
	result.click_par = { ...click_par };

	result.cur_page_par = {
		...result.cur_page_par, ...url_page_par
	}
	let firstLevelParams = JDMD(page_name, page_name_cn, ref_page_name, ref_page_url);
	firstLevelParams.click_par = { ...click_par };
	let ext_par = {
		client_ip: addressIpInfo&&addressIpInfo["ip"] ? addressIpInfo["ip"] : '',
		city_id: addressIpInfo&&addressIpInfo["adcode"] ? addressIpInfo["adcode"] : '',
		city_id_pos: addressIpInfo ? (addressIpInfo["province"] == addressIpInfo["city"] ? `${addressIpInfo["city"]}${addressIpInfo["district"]}` : `${addressIpInfo["province"]}${addressIpInfo["city"]}${addressIpInfo["district"]}`) : '',
	}
	let list = [];
	clickList.forEach((item) => {
		let temp = _.cloneDeep(result, true);
		temp['click_id'] = item.key;
		temp['click_name'] = item.label;
		temp.click_par.click_name = item.label;
		// 批批量京东埋点
		firstLevelParams.click_id = item.key;
		firstLevelParams.click_par.click_name = item.label;
		DJMtaUtils.sendClickData(firstLevelParams,ext_par,'hibo_h5_click')
		list.push(temp);
	});
	// return logJDDJAPI(list);
}
/**
 * 埋点click
 */
export async function mtClick(page_name, page_name_cn, clickId, clickName, ref_page_name, ref_page_url, click_par, url_page_par) {

	let addressIpInfo = JSON.parse(localStorage.getItem('addressIpInfo'));
	let commInfo = mtCommon(page_name, page_name_cn, ref_page_name, ref_page_url)
	let result = { ...commInfo };
	result.log_data_type = "hibo_h5_click";
	result.click_id = clickId
	result.click_par = { ...click_par, click_name: clickName };

	result.cur_page_par = {
		...result.cur_page_par, ...url_page_par
	}
	let firstLevelParams = JDMD(page_name, page_name_cn, ref_page_name, ref_page_url);
	firstLevelParams.click_id = clickId;
	firstLevelParams.click_par = { ...click_par, click_name: clickName };
	let ext_par = {
		client_ip: addressIpInfo&&addressIpInfo["ip"] ? addressIpInfo["ip"] : '',
		city_id: addressIpInfo&&addressIpInfo["adcode"] ? addressIpInfo["adcode"] : '',
		city_id_pos: addressIpInfo ? (addressIpInfo["province"] == addressIpInfo["city"] ? `${addressIpInfo["city"]}${addressIpInfo["district"]}` : `${addressIpInfo["province"]}${addressIpInfo["city"]}${addressIpInfo["district"]}`) : '',
	}
	DJMtaUtils.sendClickData(firstLevelParams,ext_par,'hibo_h5_click')
	// return logJDDJAPI([result]);
}
export function mtCommon(page_name, page_name_cn, ref_page_name, ref_page_url, isOpen) {
	let addressIpInfo = JSON.parse(localStorage.getItem('addressIpInfo'));
	let result = {};
	let userInfo = getUserInfo() || {};
	let orgInfo = getOrgGlobal() || {};
	result.client_unixtime = moment().format('X'); //访问时间
	result.network = "宽带"; //网络类型
	result.client_ip = addressIpInfo ? addressIpInfo["ip"] : '';
	if (localStorage.getItem('browerType')) {
		result.model = localStorage.getItem('browerType');

	}
	else {

		let browserInfo = new Browser();
		console.log(browserInfo);
		if (browserInfo.browser.indexOf('360') > -1) {
			localStorage.setItem('browerType', '360');
			result.model = '360';
		}
		else {
			let browerType = myBrowser();
			result.model = browerType;

			localStorage.setItem('browerType', browerType);
		}
	}
	result.os_ver = detectOS();
	result.city_id = addressIpInfo ? addressIpInfo["adcode"] : '';
	result.city_id_pos = addressIpInfo ? (addressIpInfo["province"] == addressIpInfo["city"] ? `${addressIpInfo["city"]}${addressIpInfo["district"]}` : `${addressIpInfo["province"]}${addressIpInfo["city"]}${addressIpInfo["district"]}`) : '';
	result.device_id = getCookie('deviceId');
	result.user_id = userInfo?.userPin;
	result.os_ver = detectOS();
	result.channel_code = `${window.location.href.indexOf('https://zt.hiboos.com/') > -1 ? '正' : '测'}式环境`;
	result.platform_type = "H5";
	result.url = window.location.href;
	let pageInfo = {};
	let tempPageInfo = sessionStorage.getItem(RE_PAGE_INFO);
	try {
		pageInfo = JSON.parse(tempPageInfo);
	} catch (e) {
		pageInfo = {}
	}
	if (isOpen == 1) {
		result.ref_page = ref_page_name;
		result.ref_url = ref_page_url;
		result.cur_page = page_name;
		result.cur_page_par = {
			store_id: userInfo?.orgCode,
			store_name: orgInfo.orgName,
			user_name: userInfo?.userName,
			page_name: page_name_cn,
			// 用户电脑分辨率
			client_height: window.screen.height,
			client_width: window.screen.width,

		}
	}
	else {
		result.ref_page = pageInfo?.ref_page_name || ref_page_name;
		result.ref_url = ref_page_url || pageInfo?.ref_page_url;
		result.cur_page = pageInfo?.currentPageName;
		result.cur_page_par = {
			store_id: userInfo?.orgCode,
			store_name: orgInfo.orgName,
			user_name: userInfo?.userName,
			page_name: pageInfo?.currentPageCn || page_name_cn,
			// 用户电脑分辨率
			client_height: window.screen.height,
			client_width: window.screen.width,

		}
	}

	result.ref_page_par = {

	}
	return result;
}

export function JDMD(page_name, page_name_cn, ref_page_name, ref_page_url, isOpen) {
	let pageInfo = {};
	let tempPageInfo = sessionStorage.getItem(RE_PAGE_INFO);
	try {
		pageInfo = JSON.parse(tempPageInfo);
	} catch (e) {
		pageInfo = {}
	}
	let userInfo = getUserInfo() || {};
	let orgInfo = getOrgGlobal() || {};
	let firstLevelParams = {};
	firstLevelParams.ref_url = ref_page_url || pageInfo?.ref_page_url; // 来源页面url链接
	firstLevelParams.ref_page_name = pageInfo?.ref_page_name || ref_page_name; // 来源页面名称
	firstLevelParams.page_name = pageInfo?.currentPageName; // 当前页面名称
	firstLevelParams.url = window.location.href.split('?')[0]; // 当前页面url
	// 请求参数，来源页以及落地页都在这个里面，
	firstLevelParams.url_page_par = {
		store_id: userInfo?.orgCode,
		store_name: orgInfo.orgName,
		user_name: userInfo?.userName,
		page_name: pageInfo?.currentPageCn || page_name_cn,
		// 用户电脑分辨率
		client_height: window.screen.height,
		client_width: window.screen.width,
	}
	return firstLevelParams;
}