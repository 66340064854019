import './public-path';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import actions from './shared/actions.js';
import { PhoneNumberProvider } from '@/utils/PhoneNumberContext';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

let root;
function render(props) {
  if(props){
    actions.setActions(props);
  }
  const { container } = props;
  root = ReactDOM.createRoot(container ? container.querySelector('#sub-app-root') : document.querySelector('#sub-app-root'));
  root.render(
    <PhoneNumberProvider>
      <App />
    </PhoneNumberProvider>  
  );
}

if (!window.__POWERED_BY_QIANKUN__) {
  render({});
}

export async function bootstrap() {
  console.log('[react16] react app bootstraped');
}

export async function mount(props) {
  console.log('[react16] props from main framework mount', props);
  render(props);
}

export async function unmount(props) {
  console.log('[react16] props from main framework unmount', props);
  const { container } = props;
  root.unmount(container ? container.querySelector('#sub-app-root') : document.querySelector('#sub-app-root'));
}
