import axios from 'axios';
import Qs from 'qs';


// 门店分组移动编辑接口
// http://192.168.104.108:9088/station/getFirstGroupStatis
export async function getRelatedStation(params) {
  const body = JSON.stringify(params);
  return axios({
    url: '/station/getUserRelated',
    method: 'get',
    params: { body },
  });
}


// 拣货单订单查询接口
export async function queryPickOrderApi(params, pageInfo) {
  const body = JSON.stringify(params || {});
  const page = JSON.stringify(pageInfo || {});

  return axios({
    url: '/wmsOrder/pickOrderStatistics',
    method: 'post',
    data: Qs.stringify({
    }),
  });
}

// 异常订单查询接口
export async function queryAbnormalOrderApi(params, pageInfo) {
  const body = JSON.stringify(params || {});
  const page = JSON.stringify(pageInfo || {});

  return axios({
    url: '/wmsOrder/unusualOrderStatistics',
    method: 'post',
    data: Qs.stringify({
    }),
  });
}

// 异常单、拣货单统计数量查询接口
export async function queryAbnormalOrderStatisticsApi(params) {
  const body = {...params,...{querySource:2}} || {};
  return axios({
    url: '/os-order/order/queryOrderNumByStatus',
    method: 'post',
    data: {body},
  });
}

// 订单列表查询接口
export async function orderQueryApi(params, pageInfo) {
  const body = JSON.stringify(params || {});
  const page = JSON.stringify(pageInfo || {});

  return axios({
    // url: '/wmsOrder/queryErpOrderByPage',
    url: '/os-order/order/queryErpOrderByPage',

    method: 'post',
    data: Qs.stringify({
      body,
      page,
    }),
  });
}
// 异常单、拣货单列表查询接口
export async function abnormalorderQueryApi(params, pageInfo) {
  const body = JSON.stringify({...params,...pageInfo,...{querySource:2}} || {});
  return axios({
    url: '/os-order/order/queryOrdersByStatus',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 订单详情查询接口
export async function orderDetailQueryApi(params, pageInfo) {
  const body = JSON.stringify(params || {});
  const page = JSON.stringify(pageInfo || {});

  return axios({
    url: '/wmsOrder/queryOrderDetailInfoById',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 查看组合品/多规格商品子品信息
export async function queryCombineMultSpecGoodsListAPI(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/gw/order/queryOrderProductInfoById',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 接单接口
export async function acceptOrderApi(params) {
  const body = JSON.stringify(params || {});
  return axios({
    url: '/wmsOrder/orderReceive',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 拣货完成接口
export async function pickCompleteApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/wmsOrder/orderPickUpFinish',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 订单取消审核
export async function cancelOrderApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/wmsOrder/auditCancelOrder',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 修改订单小费
export async function changeTipApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/wmsOrder/adjustTip',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 自配送确认接口
export async function modifySellerDeliveryApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/wmsOrder/modifySellerDelivery',
    method: 'post',
    data: Qs.stringify(params||{})
    // data: Qs.stringify({
    //   body,
    // }),
  });
}

// 确认送达接口
export async function orderFinishApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/wmsOrder/orderFinish',
    method: 'post',
    data: Qs.stringify(params||{})
    // data: Qs.stringify({
    //   body,
    // }),
  });
}

// 订单取消不再配送
export async function stopOrderDeliveryApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/wmsOrder/stopOrderDelivery',
    method: 'post',
    data: Qs.stringify(params||{})
    // data: Qs.stringify({
    //   body,
    // }),
  });
}

// 退回
export async function deliveryBackApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/wmsOrder/confirmReceipt',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 驳回退回
export async function refuseDeliveryBackApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/wmsOrder/waitDeliveryBackReject',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}
// 修改商品数量
export async function updateSkuInfoApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/wmsOrder/adjustQuantity',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 商品库存查询
export async function querySkuStock(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/wmsOrder/orderSkuStockQuery',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 取货失败check
export async function checkPickFailApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/wmsOrder/collectGoodFailureAudit',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 取货失败check
export async function refuseCheckPickFailApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/wmsOrder/collectGoodFailureReject',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}


// 商品库存查询
export async function replyRemaindApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/wmsOrder/hustledOrder',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 订单打印
export async function orderPrintApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/wmsOrder/print',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 获取催单原因
export async function getReplyReasonApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/wmsOrder/getReplyRemindOrderType',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 订单调度接口
export async function dispatchOrderApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/wmsOrder/dispatchOrder',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}


export async function wmsOrderPrintByOrderId  (printOrderRequestList) {
  const body = JSON.stringify(printOrderRequestList || {});

  return axios({
    url: '/wmsOrder/printByOrderId',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 转自送接口
export async function changeVenderDeliveryApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/order/changeVenderDelivery',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 非自配送 重新抛单接口
export async function repeatDispatchDeliveryApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/order/repeatDispatchDelivery',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 自配送 重新抛单接口
export async function reThrowOrderApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/order/reThrowOrder',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}


//wii ======== 派单 复核
//派单复核列表
export async function selectOrderListByPageApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/pickgoods/order/selectOrderListByPage',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}
//获取门店所有拣货员
export async function queryAllPickerListApi(params) {
  const body = JSON.stringify(params || {});
  return axios({
    url: '/pickgoods/order/queryAllPickerList',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}
//指派订单接口
export async function dDispatchOrderApi(params) {
  const body = JSON.stringify(params || {});
  return axios({
    url: '/pickgoods/order/dispatchOrder',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}
//复核完成接口
export async function recheckOrderFinishApi(params) {
  const body = JSON.stringify(params || {});
  return axios({
    url: '/pickgoods/order/recheckOrderFinish',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}
//获取复核订单的详情页
export async function recheckOrderDetailsApi(params) {
  const body = JSON.stringify(params || {});
  return axios({
    url: '/pickgoods/order/recheckOrderDetails',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}
//扫码复核和手动复核接口
export async function scanReCheckApi(params) {
  const body = JSON.stringify(params || {});
  return axios({
    url: '/pickgoods/order/scanReCheck',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}
//订单调整接口
export async function adjustOrderApi(params) {
  const body = JSON.stringify(params || {});
  return axios({
    url: '/pickgoods/order/adjustOrder',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}
//查询角标订单的数量
export async function queryOrderAmountApi(params) {
  const body = JSON.stringify(params || {});
  return axios({
    url: '/pickgoods/order/queryOrderAmount',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}
//查询门店的权限信息
export async function queryStationAuthorityApi(params) {
  const body = JSON.stringify(params || {});
  return axios({
    url: '/pickgoods/order/queryStationAuthority',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

//查询云打印队列
export async function queryPrintQueueActionApi(params) {
  const body = JSON.stringify(params || {});
  return axios({
    url: '/pickgoods/order/queryPrintOrderByStationId',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

//查询iot打印队列
export async function queryIotPrintQueueActionApi(params,pageInfo) {
  const body = JSON.stringify(params || {});
  const page = JSON.stringify(pageInfo || {});

  return axios({
    url: '/print/getPrintQueuePage',
    method: 'post',
    data: Qs.stringify({
      body,
      page
    }),
  });
}

// 取消门店所有的打印请求
export async function cancelPrintOrderByStationIdApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/pickgoods/order/cancelPrintOrderByStationId',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 取消门店所有的打印请求
export async function refreshPrintStatusByStationIdApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/print/updatePrinterStatus',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

//风控订单-取消订单接口
export async function riskControlOrderCancelApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/wmsOrder/confirm',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

//获取订单支付详情
export async function queryOrderPayDetailsApi(params) {
  const body = JSON.stringify(params || {});
  return axios({
    url: '/order/queryOrderPayments',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

//获取订单退差信息接口
export async function queryOrderRegressionInfoApi(params) {
  const body = JSON.stringify(params || {});
  return axios({
    url: '/wmsOrder/skuBackPrice/backPriceSkuList',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

//订单退差计算接口
export async function orderRegressionCalculationApi(params) {
  const body = JSON.stringify(params || {});
  return axios({
    url: '/wmsOrder/skuBackPrice/calculateBackMoney',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

//订单退差提交接口
export async function orderRegressionSubmitApi(params) {
  const body = JSON.stringify(params || {});
  return axios({
    url: '/wmsOrder/skuBackPrice/submitSkuBackPrice',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 打印队列批量移除接口
export async function removeIotPrintQueueApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/print/batchRemoveQueue',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 打印队列批量重试接口
export async function repeatIotPrintQueueApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/print/batchRetryQueue',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 打印队列批量重试接口
export async function queryIotPrintStatusApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: 'print/queryIotPrintStatus',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

//批量添加打印队列接口（iot专用）
export async function pushIotPrintApi(params) {
  const body = JSON.stringify(params || {});
  return axios({
    url: '/print/generalPushPrintOrder ',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 自提码核验接口
export async function checkSelfPickCodeApi(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/order/checkSelfPickCode',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}


// 拣货打印系统解耦灰度接口
export async function getPickPrintSpilt(params) {
  const body = JSON.stringify(params || {});

  return axios({
    url: '/print/pickPrintSpilt',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 手机号支持后四位查询灰度接口
export async function getPhoneQueryLastFourGrayscaleApi(params) {
  const body = JSON.stringify(params || {});
  return axios({
    url: '/order/isOrderOrgCode',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 查询组合品列表
export async function queryCombinationGoodsListApi(params) {
  const body = JSON.stringify(params || {});
  return axios({
    url: '/order/isOrderOrgCode',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 更新商品售卖状态
export async function setGoodsSaleStatusApi(params) {
  const body = JSON.stringify(params || {});
  return axios({
    url: '/os-product/station/updateStationProduct',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}

// 平台配送转配送中台
export async function convertYztDeliveryApi(params) {
  return axios({
    url: '/wmsOrder/modifyYztDelivery',
    method: 'post',
    data: Qs.stringify(params||{})
  });
}

// 新订单列表
export async function orderManageListApi(params, pageInfo) {
  // 处理商家
  params.orgCodeNos = [params.orgCode];
  // 处理订单状态
  if (params.orderStatus === '120') {
    params.orderStatus = params.curExceptBtnValue;
  }
  // 处理门店多选
  if (params.stationNos.length == 1 && params.stationNos.includes('-1')) {
    params.stationNos = [];
  }
  const body = JSON.stringify(params || {});
  const page = JSON.stringify(pageInfo || {});
  return axios({
    url: '/os-order/order/getOrderInfoNewByPage',
    method: 'post',
    data: Qs.stringify({
      body,
      page,
    }),
  });
}
// 新订单数量
export async function orderManageCountApi(params) {
  // 处理商家
  params.orgCodeNos = [params.orgCode];
  // 处理订单状态
  params.orderStatus = 0;
  // 处理门店多选
  if (params.stationNos.length == 1 && params.stationNos.includes('-1')) {
    params.stationNos = [];
  }
  const body = JSON.stringify(params || {});
  return axios({
    url: '/os-order/order/getOrderInfoStatusCount',
    method: 'post',
    data: Qs.stringify({
      body,
    }),
  });
}
//导出订单新
export async function exportOrderNewAPI(params) {
  // 处理商家
  params.orgCodeNos = [params.orgCode];
  // 处理订单状态
  if (params.orderStatus === '120') {
    params.orderStatus = params.curExceptBtnValue;
  }
  // 处理门店多选
  if (params.stationNos.length == 1 && params.stationNos.includes('-1')) {
    params.stationNos = [];
  }
  return axios({
    url: '/os-order/order/orderDetailNewExport',
    method: 'post',
    data: Qs.stringify({ body: JSON.stringify(params) }),
  });
}
// 导出订单商品
export async function exportOrderProductDetailNewAPI(params) {
  // 处理商家
  params.orgCodeNos = [params.orgCode];
  // 处理订单状态
  if (params.orderStatus === '120') {
    params.orderStatus = params.curExceptBtnValue;
  }
  // 处理门店多选
  if (params.stationNos.length == 1 && params.stationNos.includes('-1')) {
    params.stationNos = [];
  }
  return axios({
    url: '/os-order/order/orderDetailProductNewExport',
    method: 'post',
    data: Qs.stringify({ body: JSON.stringify(params) }),
  });
}
