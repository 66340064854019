import React, { useEffect } from 'react';
import Apply from '@/components/Apply';
import Footer from '@/components/Footer';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // 跳转后滚动到顶部
  }, [])
  return (
    <div className='about_wrapper'>
      <div className='carousel_wrapper_about'>
        <img
          style={{ width: '100%' }}
          // src={require('@/images/about/about_us_bg.webp')}
          src={`https://static.hiboos.com/static/yzt-home/about/about-banner.webp`}
          alt=""
        />
      </div>
      <div className='about_main_wrapper'>
        <div className='about_content'>
          <div className='wrapper'>
            <div className='title'>
              <p className='title_big'>达达海博系统</p>
              <p className='title_small'>数字化中台SaaS系统</p>
            </div>
            <div className='content'>
              <p style={{ textIndent: '2em' }}>达达海博隶属于北京达冠信息技术有限公司，是京东集团达达事业部自主研发的数字化中台SaaS系统，专注为本地零售商家提供全流程的即时零售数字化解决方案。海博系统从商品管理、履约管理、库存管理、员工管理、财务管理、供应链管理等商家经营环节着手 ，全链路、全场景的助力商家降本增效 ，提升经营效率，为消费者提供到家购物体验。截至2024年5月底，海博系统已覆盖300多个商家14000+门店。</p>
            </div>
          </div>
          <div className='report_wrapper'>
            <div className='title'>新闻报道</div>
            <div className='content'>
              <p><a href="https://mp.weixin.qq.com/s/EFywJJkUC9-4mGzUT7WnhQ" target="_blank">央视：海博系统618大显身手，助力商家销量飙升黑科技！</a></p>
              <p><a href="https://mp.weixin.qq.com/s/Olp5ijVAb_Xk9HdIvxJ3zw" target="_blank">海博再获殊荣！入选CCFA2021年零售技术创新最佳实践案例</a></p>
              <p><a href="http://www.linkshop.com.cn/web/archives/2021/465242.shtml" target="_blank">卜蜂莲花进化：达达海博系统推动全渠道数字化</a></p>
              <p><a href="https://www.163.com/dy/article/G6IPSPN90519B9ER.html" target="_blank">线上业绩成零售商新指标，第三方到家平台进入“深水区”</a></p>
            </div>
          </div>
        </div>
        <Apply />
        <Footer />
      </div>
    </div>
  );
}
export default About
