import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './index.scss';
const contentList = [
  {
    text: '超市便利',
    url: 'supermarkets',
  },
  {
    text: '前置仓',
    url: 'front-warehouse',
  },
  {
    text: '零售小店',
    url: 'take-out-v',
  },
]
const ApplyModal = (props) => {
  const { isShow, zIndex } = props
  const navigate = useNavigate()

  return (
    <div className='navigation_modal_wrapper'>
      <div className='title'>
        <span>您好，请选择店铺类型，为您推荐产品内容</span>
        <img
          style={{ cursor: 'pointer' }}
          width={16}
          height={16}
          src={require('@/images/modal_close.png')}
          alt=""
          onClick={() => {
            props.onClose();
          }}
        />
      </div>
      <div className='item_content'>
        {contentList.map((item, index) => {
          return (
            <div
              key={index}
              className='item'
              onClick={() => {
                navigate(`/${item.url}`);
                props.onClose();
              }}
            >
              {item.text}
            </div>
          )
        })}
      </div>
    </div>
  );
}
export default ApplyModal
