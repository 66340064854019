import React, { useEffect, useState, useRef } from "react";
import Apply from "@/components/Apply";
import Footer from "@/components/Footer";
import ApplyModal from "@/components/ApplyModal";
import BrandList from "@/components/BrandList";
import haiboLong from "@/images/supermarket/haiboLong.png";
import UnlimitedCarousel from "@/components/UnlimitedCarousel";
const topTabList = [
  {
    key: 1,
    label: "商品",
    imgUrl: "goods_manage",
    icon: "goods_manage_icon",
  },
  {
    key: 2,
    label: "营销",
    imgUrl: "sale_manage",
    icon: "sale_manage_icon",
  },
  {
    key: 3,
    label: "会员",
    imgUrl: "vip",
    icon: "vip_icon",
  },
  {
    key: 4,
    label: "履约",
    imgUrl: "agreement",
    icon: "agreement_icon",
  },
  {
    key: 5,
    label: "客服",
    imgUrl: "custom_service",
    icon: "custom_service_icon",
  },
  {
    key: 6,
    label: "数据",
    imgUrl: "data_manage",
    icon: "data_manage_icon",
  },
  {
    key: 7,
    label: "对账",
    imgUrl: "account",
    icon: "account_icon",
  },
  {
    key: 8,
    label: "核销",
    imgUrl: "verfication",
    icon: "verfication_icon",
  },
];
const serviceList = [
  {
    title: "项目启动",
    time: "3天",
    itemList: ["合作协议签署", "项目启动会", "成立专项小组"],
  },
  {
    title: "系统对接",
    time: "10天",
    itemList: ["提供方案排期", "技术实施开发", "测试验证报告"],
  },
  {
    title: "上线筹备",
    time: "6天",
    itemList: ["总部门店培训", "数据整理配置", "线上流程验证"],
  },
  {
    title: "系统上线",
    time: "1天",
    itemList: ["产品手册资料", "运营/系统指导", "定期复盘/培训"],
  },
];
const agreementList = [
  {
    title: "履约多场景匹配",
    text: "按单拣货、分区拣货、店内仓、自动分拨",
    styles: {
      width: "430px",
      height: "391px",
    },
    imgUrl: "agreement_moreScene",
  },
  {
    title: "履约数据分析",
    text: "履约质量实时跟踪、履约报表分析、实时趋势追踪",
    styles: {
      width: "465px",
      height: "381px",
    },
    imgUrl: "agreement_data",
  },
  {
    title: "员工绩效管理",
    text: "两种绩效激励机制，根据员工拣货数据自动结算",
    styles: {
      width: "619px",
      height: "358px",
    },
    imgUrl: "agreement_points",
  },
  {
    title: "多场景的打印方案矩阵供给能力",
    text: "适配各种打印场景，保障打印稳定性和操作便捷性",
    styles: {
      width: "600px",
      height: "289px",
    },
    imgUrl: "agreement_print",
  },
];
let tabIndex = 1;
const SuperMarket = () => {
  const [tabActive, setTabActive] = useState(1);
  const [tabsIcon, setTabIcon] = useState("goods_manage_icon");
  const [tabImgUrl, setTabImgUrl] = useState("goods_manage");
  const [showModal, setShowModal] = useState(false);
  const [agreementRight, setAgreementRight] = useState(agreementList[0]);
  const [isClicked, setIsClicked] = useState(false); // 是否点击div
  const supermarketIntervalRef = useRef(null);
  useEffect(() => {
    // 点击div之后不再设置定时器
    if (!isClicked) {
      startInterval();
    }
    // 清除定时器
    return () => clearInterval(supermarketIntervalRef.current);
  }, [isClicked]);

  useEffect(() => {
    window.scrollTo(0, 0); // 跳转后滚动到顶部
  }, []);

  const startInterval = () => {
    supermarketIntervalRef.current = setInterval(() => {
      tabIndex += 1;
      if (tabIndex > 8) {
        tabIndex = 1;
      }
      setTabActive(tabIndex);
      setTabIcon(topTabList[tabIndex - 1].icon);
      if (tabIndex != 4) {
        setTabImgUrl(topTabList[tabIndex - 1].imgUrl);
      }
    }, 2000);
  };
  const stopInterval = () => {
    clearInterval(supermarketIntervalRef.current);
  };

  const modalShow = () => {
    if (!showModal) {
      setShowModal(true);
    }
  };
  const onCancel = () => {
    setShowModal(false);
  };

  const handleChange = (key, icon, tabImgUrl) => {
    setTabActive(key);
    setTabIcon(icon);
    if (key != 4) {
      setTabImgUrl(tabImgUrl);
    }
  };
  const onMouseOver = (item) => {
    setAgreementRight(item);
  };
  return (
    <div className="supermarket_page_wrapper">
      {/* <Connect /> */}
      <ApplyModal isShow={showModal} onCancel={onCancel} />
      <div className="carousel_wrapper_front">
        <img
          style={{ width: "100%" }}
          src={`https://static.hiboos.com/static/yzt-home/homePage/supermarkets.webp`}
          // src={require(`@/images/homePage/supermarkets.webp`)}
          alt=""
        />
        <p className="opt_btn" onClick={modalShow}>
          免费咨询
        </p>
        <div className="banner_carousel_container">
          <div className="banner_carousel_text">
            <p>超过80%的合作伙伴关系持续了5年以上</p>
            <img
              src={require(`@/images/downArrow.png`)}
              width={16}
              height={17}
              style={{marginLeft: '5px'}}
              alt=""
            />
          </div>
          <div className="small_carousel_wrapper">
            <UnlimitedCarousel
              idname="supermarket"
              imgsrc={require(`@/images/homePage/supermarket_carousel_long.png`)}
              wrapStyles={{ width: "1050px", height: "95px" }}
              styles={{ width: "1050px", height: "75px" }}
            />
          </div>
        </div>
      </div>

      <div className="work_scene">
        <div className="supermarket_pain_spot">
          <div>
            <p className="title_big">商超运营痛点</p>
          </div>

          <div className="wrapper pain_spot_icon">
            <img
              height={450}
              src={require("@/images/supermarket/supermarket_pain_point.png")}
              alt=""
            />
          </div>
        </div>
        {/* 解决方案 */}
        <div className="supermarket_rx">
          <img
            width={"100%"}
            src={require("@/images/supermarket/supermarket_rx_bg.webp")}
            alt=""
          />
        </div>
        {/* 核心优势 */}
        <div className="supermarket_core_advantage">
          <p className="title_big">产品功能/核心优势</p>
          <div
            className="card_top_wrapper"
            onMouseEnter={stopInterval}
            onMouseLeave={() => {
              if (!isClicked) {
                startInterval();
              }
            }}
          >
            <div className="wrapper tabs_wrapper">
              {topTabList.map((item) => {
                return (
                  <div
                    key={item.key}
                    // className='card_item'
                    className={`card_item ${isClicked ? "active" : ""}`}
                    onClick={() => {
                      handleChange(item.key, item.icon, item.imgUrl);
                      setIsClicked(true);
                      clearInterval(supermarketIntervalRef.current);
                    }}
                  >
                    <div className="icon">
                      {/* <img width={40} height={40} src={`https://static.hiboos.com/static/yzt-home/frontWarehouse/${item.icon}.png`} alt="" /> */}
                      <img
                        width={40}
                        height={40}
                        src={require(`@/images/supermarket/${item.icon}.png`)}
                        alt=""
                      />
                    </div>
                    <div
                      className={`content ${
                        tabsIcon == item.icon ? "content_active" : ""
                      }`}
                    >
                      {item.label}
                    </div>
                  </div>
                );
              })}
            </div>
            {tabActive == 4 ? (
              <div className={`card_content_finance`}>
                <p className="title_big">全渠道订单履约</p>
                <p className="title_small">
                  拣货作业-人员绩效分析-绩效结算全流程无缝衔接
                </p>
                <div className="main_content">
                  <div className="left_content">
                    {agreementList.map((item, index) => {
                      return (
                        <div
                          key={index}
                          // className='item'
                          className={
                            agreementRight.imgUrl == item.imgUrl
                              ? "item active"
                              : "item"
                          }
                          onMouseOver={() => {
                            onMouseOver(item);
                          }}
                        >
                          <div className="title">{item.title}</div>
                          <div className="text">{item.text}</div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="right_content">
                    <img
                      style={agreementRight.styles}
                      src={require(`@/images/supermarket/${agreementRight.imgUrl}.png`)}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`card_content ${
                  tabActive != 4 ? "active" : "inactive"
                }`}
              >
                {/* <img height={600} src={`https://static.hiboos.com/static/yzt-home/frontWarehouse/${tabImgUrl}.png`} alt="" /> */}
                <img
                  height={600}
                  src={require(`@/images/supermarket/${tabImgUrl}.png`)}
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
        {/* 服务体系 */}
        <div className="supermarket_service_standard">
          <div className="wrapper">
            <p className="title_big">完善高效的项目实时服务体系</p>
            <p className="title_small">4个阶段的实施配合，20天即可上线</p>
            <div className="main_content">
              {serviceList.map((item, index) => {
                return (
                  <div className="content_item" key={index}>
                    {index < 3 ? (
                      <div className="ellipse_icon">
                        <img
                          width={32}
                          height={50}
                          src={require("@/images/takeout/ellipse_icon.png")}
                          alt=""
                        />
                      </div>
                    ) : null}
                    <div className="title">
                      <span className="icon"></span>
                      <span className="text">{item.title}</span>
                      <span className="work_date">{item.time}</span>
                    </div>
                    <div className="item_wrapper">
                      {item.itemList.map((items, indexs) => {
                        return (
                          <p className="item_text" key={indexs}>
                            {items}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* 7*24小时售后服务 */}
        <div className="after_service">
          <div className="wrapper">
            <p className="title_big">专业团队实施 7*24小时售后服务</p>
            <div className="card_wrapper">
              <div className="card_item card_item_left">
                <div className="icon"></div>
                <p className="title">售后&技术支持</p>
                <p className="text">
                  7*24小时值班响应，实时数据监测报警、可追踪、可修复。
                </p>
              </div>
              <div className="card_item card_item_center">
                <div className="icon"></div>
                <p className="title">新功能培训</p>
                <p className="text">
                  海博产品每年迭代30余次，专业售后团队提供在线文档和人工咨询进行培训。
                </p>
              </div>
              <div className="card_item card_item_right">
                <div className="icon"></div>
                <p className="title">解决方案服务</p>
                <p className="text">
                  根据商家业务发展现状，提供不同阶段的运营+产品的解决方案。
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="customer_case">
          <p className="title_big">客户案例</p>
          <p className="title_small">
            山东区域领先的零售行业连锁品牌，中国零售连锁百强企业，覆盖便利店、大卖场等多种业态
          </p>
          <img
            height={541}
            src={require("@/images/supermarket/customer_case_main.png")}
            alt=""
          />
        </div>
        <div className="partner_wrapper">
          <div className="wrapper">
            <p className="title_big">部分优秀合作伙伴</p>
            {/* <div className='item_wrapper'>
            <img width={'100%'} src={require('@/images/supermarket/partner_list.png')} alt="" />
          </div> */}
            <BrandList imgsrc={haiboLong} styles={{ width: "2688px" }} />
          </div>
        </div>
        <Apply />
        <Footer />
      </div>
    </div>
  );
};
export default SuperMarket;
