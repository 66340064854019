/**
*	Toast('文案')
	Toast({
		msg: '文案', //toast文案
		customClass: 'my-class', //传入样式
		onClose: () => {}, //toast消失时回调
		duration: 3000, //toast 多少毫秒后消失
	})
**/
//@todo toast消失加动画？
import React from 'react'
import ReactDOM from 'react-dom'

import ToastBox from './ToastBox'
import './index.scss'

export default function Toast(props = {}) {
    const el = (props && props.el) || document.body
    const div = document.createElement('div')
    el.appendChild(div)

    if (typeof props === 'string' || React.isValidElement(props)) {
        props = {
            msg: props
        }
    }

    // props.msg必须是有内容
    if(props && props.msg && (React.isValidElement(props.msg)||(typeof props.msg==='string'))){
        const component = React.createElement(
            ToastBox,
            Object.assign(props, {
                willUnmount: () => {
                    ReactDOM.unmountComponentAtNode(div)
                    el.removeChild(div)

                    if (props.onClose instanceof Function) {
                        props.onClose()
                    }
                }
            })
        )

        ReactDOM.render(component, div);
    }
}
