import React, { useState, useEffect } from "react";
import "./index.scss";

const UnlimitedCarousel = (props) => {
  const { idname = "unlimitedCarousel", wrapStyles, styles, imgsrc } = props;

  useEffect(() => {
    var dom = document.getElementById(idname);
    var timer = null;
    var len = dom.offsetWidth;
    dom.innerHTML += dom.innerHTML;
    dom.style.width = len * 2 + "px";
    function slider() {
      dom.style.left = dom.offsetLeft - 1 + "px";
      if (dom.offsetLeft < -dom.offsetWidth / 2) {
        dom.style.left = "0";
      }
    }
    timer = setInterval(slider, 20);
    dom.onmouseover = function () {
      clearInterval(timer);
    };
    dom.onmouseout = function () {
      timer = setInterval(slider, 20);
    };
  }, []);

  return (
    <div className="carousel-wrap" style={{ ...wrapStyles }}>
      <div id={idname} className="carousel-box">
        <img
          src={imgsrc}
          alt=""
          className="carouselImg"
          style={{ ...styles }}
        />
      </div>
    </div>
  );
};
export default UnlimitedCarousel;
