import { api_ajax1 } from './http';
import axios from './http';
import qs from 'qs';

function createUUID() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
	  var r = (Math.random() * 16) | 0,
		v = c == 'x' ? r : (r & 0x3) | 0x8;
	  return v.toString(16);
	});
}

export function api_submitForm(options){
	var data = {
		functionId:'commons/venderApply',
		body: options || {}
	};
	return api_ajax1(data,'POST');
}

// 规则中心导航栏菜单
export function api_firstMenu(options) {
	var data = {
		functionId: 'getRuleCenter/ruleMenu',
		body: options || {}
	};
	return api_ajax1(data);
}

// 获取规则中心文章列表分页
export function api_articleList(options) {
	var data = {
		functionId: 'getRuleCenter/ruleArticle',
		body: options || {}
	};
	return api_ajax1(data);
}

// 获取最新公告数据
export function api_articleLast(options) {
	var data = {
		functionId: 'getRuleCenter/latestArticle',
		body: options || {}
	};
	return api_ajax1(data);
}

// 获取规则内容
export function api_articleContain(options) {
	var data = {
		functionId: 'getRuleCenter/ruleArticleDetail',
		body: options || {}
	};
	return api_ajax1(data);
}

// 海博落地页手机号获取接口
export function api_haiboPhone(options) {
	return axios({
		method: 'POST',
		url: '/ops/customerInfo/selectReserveByPhone',
		data: qs.stringify(options),
		headers:{
			'x-forwarded-source':'hibo_pc',
			'x-forwarded-device':createUUID(),
		},
		timeout: 9999
	})
}

// 海博落地页新增客户信息接口
export function api_addCustomerInfo(options) {
	const body = JSON.stringify(options || {});
	// var data = {
	// 	functionId: '/api/customer/info/addCustomerInfo',
	// 	body: options || {}
	// };
	// return api_ajax1(data, 'POST', '', true);
	return axios({
		method: 'POST',
		// url: '/ops/customerInfo/add',
		url: '/landing/page/addCustomerInfo',
		data: qs.stringify({body}),
		headers:{
			'x-forwarded-source':'hibo_pc',
			'x-forwarded-device':createUUID(),
		},
		timeout: 9999
	})
}

export {
	logJDDJAPI,
	mtClick,
} from './log'

