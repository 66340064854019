import pathToRegexp from 'path-to-regexp';

/**
 * 格式化菜单数据结构，如果子菜单有权限配置，则子菜单权限优先于父级菜单的配置
 * 如果子菜单没有配置，则继承自父级菜单的配置
 * @param {Array} menuData
 * @param {String} parentPath
 * @param {string} parentAuthority
 */
function formatterMenuData(menuData, parentPath = '', parentAuthority) {
  return menuData.map((item) => {
    const { path } = item;
    const result = {
      ...item,
      path,
      authority: item.authority || parentAuthority,
    };
    if (item.children) {
      result.children = formatterMenuData(
        item.children,
        `${parentPath}${item.path}`,
        item.authority
      );
    }
    return result;
  });
}

/**
 * 将 Array 结构的 Menu 数据转化成以 path 为 key 的 Object 结构
 * 扁平化 Menu 数据，通过递归调用将父子层结构的数据处理为扁平化结构
 * @param {array} menuConfig
 *
 * eg：
 *  "/dashboard": {name: "dashboard", icon: "dashboard", path: "/dashboard", children: Array(3), authority: undefined}
 *  "/form": {name: "表单页", icon: "form", path: "/form", children: Array(3), authority: undefined}
 *  "/list": {name: "列表页", icon: "table", path: "/list", children: Array(4), authority: undefined}
 */
function getFlatMenuData(menuConfig) {
  let keys = {};
  menuConfig.forEach((item) => {
    if (item.children) {
      keys[item.path] = { ...item };
      keys = { ...keys, ...getFlatMenuData(item.children) };
    } else {
      keys[item.path] = { ...item };
    }
  });
  return keys;
}

/**
 *
 * @param {Array}  routerConfig
 * @param {Object} menuConfig
 */
function getRouterData(routerConfig, menuConfig) {
  const menuData = getFlatMenuData(formatterMenuData(menuConfig));

  const routerData = [];

  routerConfig.forEach((item, index) => {
    // 匹配菜单中的路由，当路由的 path 能在 menuData 中找到匹配（即菜单项对应的路由），则获取菜单项中当前 path 的配置 menuItem
    // eg.  router /product/:id === /product/123
    const pathRegexp = pathToRegexp(item.path);
    const menuKey = Object.keys(menuData).find((key) =>
      pathRegexp.test(`${key}`)
    );

    let menuItem = {};
    if (menuKey) {
      menuItem = menuData[menuKey];
    }

    let router = routerConfig[index];
    router = {
      ...router,
      name: router.name || menuItem.name,
      authority: router.authority || menuItem.authority,
    };

    routerData.push(router);
  });

  return routerData;
}
function getLocationSearchToObject (url){
  
  if ( !url) {
    url  = window.location.href;
  }
  var reg = /([^=&\s]+)[=\s]*([^&\s]*)/g;
  if ( !url) url = window.location.href;
  if(url.indexOf('?') >-1){
    
    url = url.substring(url.indexOf("?")+1);
  }
  var obj = {};
  while (reg.exec(url)) {
      obj[RegExp.$1] = RegExp.$2;
  }
  return obj;
}
function trim (value){
  if (value){
    return value.replace(/(^\s*)|(\s*$)/g, "");
  }
  return "";
}
function formatNumber(data,isHaveDecimals){
  if(data!=null){
    if ( isHaveDecimals){
      return parseFloat(data).toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,');
    }
    else {
      return `${data}`.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,');
    }
    
  }else{
    return 0;
  }
}
function styleOverFlow (type) {
  document.body.style.overflowY = type=='h'?'hidden':'scroll'; 
}
function encodeSearchKey  (key) {
  const encodeArr = [{
    code: '%',
    encode: '%25'
  }, {
    code: '?',
    encode: '%3F'
  }, {
    code: '#',
    encode: '%23'
  }, {
    code: '&',
    encode: '%26'
  }, {
    code: '=',
    encode: '%3D'
  }];
  return key.replace(/[%?#&=]/g, ($, index, str) => {
    for (const k of encodeArr) {
      if (k.code === $) {
        return k.encode;
      }
    }
  });
}
/** 
 ** 乘法函数，用来得到精确的乘法结果 
 ** 说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。 
 ** 调用：accMul(arg1,arg2) 
 ** 返回值：arg1乘以 arg2的精确结果 
 **/  
function mul(arg1, arg2) {  
  var m = 0, s1 = arg1.toString(), s2 = arg2.toString();  
  try {  
      m += s1.split(".")[1].length;  
  }  
  catch (e) {  
  }  
  try {  
      m += s2.split(".")[1].length;  
  }  
  catch (e) {  
  }  
  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);  
}  


function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  // 转换成file对象
  return new File([u8arr], filename, { type: mime });
  // 转换成成blob对象
  // return new Blob([u8arr],{type:mime});
}
function blobToFile(blob, fileName){
  blob.lastModifiedDate =new Date();
  blob.name = fileName;
  return blob;
}
/**
 * 得到一个数的小数位数
 * @param {*} num 
 */
function getDecimalPointLength(num) {
  let temp = num.toString();

  if ( temp.indexOf('.')<0){
    return 0;
  }
  else {
    return temp.split(".")[1].length;
  }
}

function setDecimalPointLength (num,len_){
  let temp = num.toString();
  if ( getDecimalPointLength(num)>len_){

    let _all = temp.split(".");
    let _interger = _all[0];
    let decimalPoint = _all[1];

    return `${_interger}.${decimalPoint.substring(0,len_)}`;
  }
  else {
    return num;
  }
}
/**
 * 得到URL前缀
 */
function getUrlPrefix () {
  let obj = window.location;


  return `${obj.origin}${obj.pathname}`;
}

function getUrlPrefixAppend() {
  return getUrlPrefix()+'#/';
}

/**
 * 模拟a标签打开页面
 * @param {*} url  新页面url地址
 * @param {*} target  新页面如何打开，默认'_blank'：在新标签页打开
 */
function openNewPage(url, target) {
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('target', target || '_blank');
  const e = document.createEvent('MouseEvents');
  e.initEvent('click', true, true);
  a.dispatchEvent(e);
}
function replaceAllInfo (content,searchValue,replaceValue) {
  while (content.indexOf(searchValue)>-1) {
    content = content.replace(searchValue,replaceValue);
    }
    return content;
}

function formatterTime(time, fmt) {

  var TIME = new Date(parseInt(time)) ;
  var z = {

      M: TIME.getMonth() + 1,

      d: TIME.getDate(),

      h: TIME.getHours(),

      m: TIME.getMinutes(),

      s: TIME.getSeconds(),

      S: TIME.getMilliseconds()

  };

  fmt = fmt.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
          return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-2);
      });

  fmt = fmt.replace(/(S+)/g, function(v) {
      return ("000" + eval('z.' + v.slice(-1))).slice(-3);
  });
  return fmt.replace(/(y+)/g, function(v) {
      return TIME.getFullYear().toString().slice(-v.length);
  });
}

//获取操作系统
function detectOS() { 
    var sUserAgent = navigator.userAgent; 
    var isWin = (navigator.platform == "Win32") || (navigator.platform == "Windows"); 
    var isMac = (navigator.platform == "Mac68K") || (navigator.platform == "MacPPC") || (navigator.platform == "Macintosh") || (navigator.platform == "MacIntel"); 
    if (isMac) return "Mac"; 
    var isUnix = (navigator.platform == "X11") && !isWin && !isMac; 
    if (isUnix) return "Unix"; 
    var isLinux = (String(navigator.platform).indexOf("Linux") > -1); 
    if (isLinux) return "Linux"; 
    if (isWin) { 
      var isWin2K = sUserAgent.indexOf("Windows NT 5.0") > -1 || sUserAgent.indexOf("Windows 2000") > -1; 
      if (isWin2K) return "Win2000"; 
      var isWinXP = sUserAgent.indexOf("Windows NT 5.1") > -1 || sUserAgent.indexOf("Windows XP") > -1; 
      if (isWinXP) return "WinXP"; 
      var isWin2003 = sUserAgent.indexOf("Windows NT 5.2") > -1 || sUserAgent.indexOf("Windows 2003") > -1; 
      if (isWin2003) return "Win2003"; 
      var isWin2003 = sUserAgent.indexOf("Windows NT 6.0") > -1 || sUserAgent.indexOf("Windows Vista") > -1; 
      if (isWin2003) return "WinVista"; 
      var isWin2003 = sUserAgent.indexOf("Windows NT 6.1") > -1 || sUserAgent.indexOf("Windows 7") > -1; 
      if (isWin2003) return "Win7"; 
      var isWin8 = sUserAgent.indexOf("Windows NT 6.2") > -1 || sUserAgent.indexOf("Windows 8") > -1; 
      if (isWin8) return "Win8"; 
      var isWin81 = sUserAgent.indexOf("Windows NT 6.3") > -1; 
      if (isWin81) return "Win8.1"; 
      var isWin10 = sUserAgent.indexOf("Windows NT 10") > -1 || sUserAgent.indexOf("Windows 10") > -1; 
      if (isWin10) return "Win10";
    } 
    return "None"; 
  } 
  //获取浏览器 类型 
function myBrowser() {
  var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
  var isIE = userAgent.indexOf("compatible") > -1
          && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
  var isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
  var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
  var isSafari = userAgent.indexOf("Safari") > -1
          && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
  var isChrome = userAgent.indexOf("Chrome") > -1
          && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器

  if (isIE) {
      var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
      reIE.test(userAgent);
      var fIEVersion = parseFloat(RegExp["$1"]);
      if (fIEVersion == 7) {
          return "IE7";
      } else if (fIEVersion == 8) {
          return "IE8";
      } else if (fIEVersion == 9) {
          return "IE9";
      } else if (fIEVersion == 10) {
          return "IE10";
      } else if (fIEVersion == 11) {
          return "IE11";
      } else {
          return "0";
      }//IE版本过低
      return "IE";
  }
  if (isOpera) {
      return "Opera";
  }
  if (isEdge) {
      return "Edge";
  }
  if (isFF) {
      return "FF";
  }
  if (isSafari) {
      return "Safari";
  }
  if (isChrome) {
      return "Chrome";
  }
  
}
// 修改url地址中的某个参数
function changeURLArg(url,arg,arg_val){ 
  var pattern=arg+'=([^&]*)'; 
  var replaceText=arg+'='+arg_val; 
  if(url.match(pattern)){ 
      var tmp='/('+ arg+'=)([^&]*)/gi'; 
      tmp=url.replace(eval(tmp),replaceText); 
      return tmp; 
  }else{ 
      if(url.match('[\?]')){ 
          return url+'&'+replaceText; 
      }else{ 
          return url+'?'+replaceText; 
      } 
  } 
  return url+'\n'+arg+'\n'+arg_val; 
} 



function calcPercentage (arr = [], needSign = true, decimalCount = 4)  {
  let sum = arr.reduce((a, b) => a + b, 0),
      D = decimalCount < 2 ? 2 : decimalCount,
      // E1, E2将小数转化成整数，解决因精度导致小数计算错误的问题
      E1 = Math.pow(10, D),
      E2 = Math.pow(10, D + 2);
  if(sum === 0) {
      return arr.map(_ => needSign ? '0%' : 0);
  }
  const perArr = arr.map((e, i) => ({
      index: i,
      percentage: Math.round((e / sum).toFixed(D) * E1)
  }));
  const all = perArr.reduce((a, b) => a + b.percentage, 0);
  // 比较计算出的百分比总和和实际总和
  if(all > E1) {
      // 根据误差大小排序
      perArr.sort((a, b) => {
          const aCur = a.percentage * Math.pow(10, 2),
              aReal = Number((arr[a.index] / sum).toFixed(D + 2)) * E2,
              bCur = b.percentage * Math.pow(10, 2),
              bReal = Number((arr[b.index] / sum).toFixed(D + 2)) * E2,
              aError = aCur - aReal,
              bError = bCur - bReal;
          return  bError - aError < 0 ? -1 : bError - aError > 0 ? 1 : a.index - b.index; // 根据误差排序，如果误差一样，索引小的在前面
      });
      let sumError = all - E1, i = 0;
      // 百分比总和多的部分，从误差大的百分比依次扣除
      while(sumError) {
          perArr[i].percentage = perArr[i].percentage - 1;
          sumError--;
          i++;
      }
  }
  if(all < E1) {
      debugger;
      // 根据误差大小排序
      perArr.sort((a, b) => {
          const aCur = a.percentage * Math.pow(10, 2),
              aReal = Number((arr[a.index] / sum).toFixed(decimalCount + 2)) * E2,
              bCur = b.percentage * Math.pow(10, 2),
              bReal = Number((arr[b.index] / sum).toFixed(decimalCount + 2)) * E2,
              aError = aCur - aReal,
              bError = bCur - bReal;
          return  aError - bError < 0 ? -1 : aError - bError > 0 ? 1 : a.index - b.index;
      });
      let sumError = all - E1, i = 0;
      // 百分比总和少的部分，从误差大的百分比依次补上
      while(sumError) {
          perArr[i].percentage = perArr[i].percentage + 1;
          sumError++;
          i++;
      }
  }
  return perArr.sort((a, b) => a.index - b.index).map(e => needSign ? (e.percentage / Math.pow(10, D - 2)).toFixed(D - 2) + '%' : e.percentage/E1);
};


export {calcPercentage,detectOS,myBrowser,replaceAllInfo,openNewPage,getUrlPrefixAppend,getUrlPrefix,setDecimalPointLength,getDecimalPointLength,dataURLtoFile,blobToFile, getFlatMenuData, getRouterData, formatterMenuData,getLocationSearchToObject,trim ,formatNumber,encodeSearchKey,styleOverFlow,mul,formatterTime, changeURLArg};
