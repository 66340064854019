// 导入组合函数
import { configureStore } from '@reduxjs/toolkit';
// 导入 子store 模块
import counterReducer from './modules/counterStore';
import orderManageReducer from './modules/orderManageStore';
// export * from './modules/counterStore';

// 该方法会生成一个 根store
const store = configureStore({
    reducer: {
        // counter ===> 子store模块名：后面在组件中使用store数据的时候会用到
        counter: counterReducer,
        orderManage: orderManageReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: ['orderManageStore/orderQueryFormNewValueChange'],
                // Ignore these field paths in all actions
                ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
                // Ignore these paths in the state
                ignoredPaths: ['orderManage.orderQueryFormNew.beginTime','orderManage.orderQueryFormNew.endTime'],
            },
        }),
});

export default store;
