// 导入创建 store 的方法
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// 导出
export const fetchAsyceList = createAsyncThunk('fetch/async', async () => {
    const { data } = await axios({
        url: 'https://gw.hiboos.com/ops/customerInfo/selectReserveByPhone',
        method: 'post',
        headers: {
            'X-Forwarded-Device': '0e256dff-eb7b-40af-a21d-480f3652f454',
            'X-Forwarded-Source': 'hibo_pc',
        }
    })
    return data;
})

const counterStore = createSlice({
    // 必填项
    name: 'counter',

    // 初始状态数据
    initialState: {
        count: 0,
        info: {contractName:'张三'},
    },

    // 修改数据 - 同步方法
    reducers: {
        // 增方法
        increment: (state, { payload }) => {
            state.count += payload || 1;
        },

        // 减方法
        decrement: (state) => {
            state.count--;
        },
        /** 增加指定数量 */
        addToNum(state, action) {
            state.count += action.payload;
        },
        changeBannerAction(state, { payload }) {},
    },
    // 额外的 reducer 执行异步操作
    extraReducers: builder => {
        // 计算属性名 [computedNames]
        builder.addCase(fetchAsyceList.pending,(state, action) => {
            console.log('fetchAsyncList 执行中还未有结果')
        }),
        builder.addCase(fetchAsyceList.fulfilled,(state, { payload }) => {
            console.log('fetchAsyncList 拿到结果了', payload)
            state.info = payload.result;
        }),
        builder.addCase(fetchAsyceList.rejected,(state, action) => {
            console.log('fetchAsyncList 错误了')
        })
    }

});

// 解构出创建 action 对象的函数（actionCreater）
// 并以 按需导出 的方式 导出 actionCreater
export const { increment, decrement, addToNum, changeBannerAction } = counterStore.actions;

// 以默认导出的方式导出 reducer函数
export default counterStore.reducer;
